import { ReactElement } from 'react';

// third-party
import firebase from 'firebase/compat/app';

// ==============================|| AUTH TYPES  ||============================== //

export type GuardProps = {
  children: ReactElement | null;
};

export type UserProfile = {
  id: number;
  email: string;
  avatar?: string;
  image?: string;
  first_name: string | null;
  last_name: string | null;
  role?: string;
  tier?: string;
  phone: string | null;
  user_status_id: number;
  birth_year?: number | null;
  gender: Gender | null;
  created: string;
};

export type AuthState = {
  isLoggedIn: boolean;
  isInitialized?: boolean;
  user?: UserProfile | null;
  token?: string | null;
  roles?: UserRole[] | null;
  activeRole?: UserRole | null;
};

export interface AuthActionProps {
  type: string;
  payload?: AuthState;
}

export type FirebaseContextType = {
  isLoggedIn: boolean;
  isInitialized?: boolean;
  user?: UserProfile | null | undefined;
  logout: () => Promise<void>;
  login: () => void;
  firebaseRegister: (email: string, password: string) => Promise<firebase.auth.UserCredential>;
  firebaseEmailPasswordSignIn: (email: string, password: string) => Promise<firebase.auth.UserCredential>;
  firebaseGoogleSignIn: () => Promise<firebase.auth.UserCredential>;
  firebaseTwitterSignIn: () => Promise<firebase.auth.UserCredential>;
  firebaseFacebookSignIn: () => Promise<firebase.auth.UserCredential>;
  resetPassword: (email: string) => Promise<void>;
  updateProfile: VoidFunction;
};

export type AWSCognitoContextType = {
  isLoggedIn: boolean;
  isInitialized?: boolean;
  user?: UserProfile | null | undefined;
  logout: () => void;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, firstName: string, lastName: string) => Promise<unknown>;
  resetPassword: (email: string) => Promise<void>;
  updateProfile: VoidFunction;
};

export interface InitialLoginContextProps {
  isLoggedIn: boolean;
  isInitialized?: boolean;
  user?: UserProfile | null | undefined;
}

export interface JWTDataProps {
  userId: string;
}

export type JWTContextType = {
  isLoggedIn: boolean;
  isInitialized?: boolean;
  user?: UserProfile | null | undefined;
  logout: () => void;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateProfile: VoidFunction;
};

export type Auth0ContextType = {
  isLoggedIn: boolean;
  isInitialized?: boolean;
  user?: UserProfile | null | undefined;
  logout: () => void;
  login: () => void;
  resetPassword: (email: string) => Promise<void>;
  updateProfile: VoidFunction;
};

export enum UserRolesEnum {
  SYSTEM_ADMIN = 1,
  SYSTEM_TECHNICIAN = 2,
  SYSTEM_SUPPORT = 3,
  COMPANY_ADMIN = 4,
  COMPANY_MANAGER = 5,
  PROPERTY_ADMIN = 6,
  PROPERTY_MANAGER = 7,
  BUILDING_OWNER = 8,
  BUILDING_MANAGER = 9,
  UNIT_TENANT = 10,
  NOTIFICATION_CONTACT = 11,
  SENSOR_TESTER = 12
}

export type UserRole = {
  id: number;
  user_id: number;
  user_role_type_id: number;
  company_id: number | null;
  property_id: number | null;
  building_id: number | null;
  unit_id: number | null;
  created: string;
  modified: string;
};

export const GENDERS = ['male', 'female', 'other'] as const;
export type Gender = typeof GENDERS[number];

export type CreateUserReq = Pick<UserProfile, 'first_name' | 'last_name' | 'email' | 'phone' | 'birth_year' | 'gender'>;
export type UpdateUserReq = CreateUserReq & Pick<UserProfile, 'id' | 'user_status_id'>;
export type AddUserRole = {
  user_id: number;
  user_role_type_id: number;
  company_id?: number | null;
  property_id?: number | null;
  building_id?: number | null;
  unit_id?: number | null;
};
