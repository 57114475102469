import React, { useEffect } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// project import
import Loader from 'components/Loader';
import { KeyedObject } from 'types/root';
import { useLazyMeQuery } from 'store/reducers/api/auth';
import { getToken } from 'utils';
import { useDispatch, useSelector } from 'store';

// redux
import { logout } from 'store/reducers/auth';
import { useLazyGetStaticQuery } from 'store/reducers/api/static';

const verifyToken: (st: string) => boolean = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const dispatch = useDispatch();
  const { isInitialized } = useSelector((state) => state.auth);
  const [getMe, { isError, isFetching }] = useLazyMeQuery();
  const [getStatic] = useLazyGetStaticQuery();

  useEffect(() => {
    const init = async () => {
      const serviceToken = getToken();
      if (serviceToken && verifyToken(serviceToken)) {
        await getMe();
        await getStatic();
      } else {
        dispatch(logout());
      }
    };

    init();
  }, [dispatch, getMe, getStatic]);

  useEffect(() => {
    if (isError) dispatch(logout());
  }, [isError, dispatch]);

  if (isFetching || !isInitialized) {
    return <Loader />;
  }

  return children;
};
