// project import
import Logo from './LogoMain';
import LogoIcon from './LogoIcon';

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
}

const LogoSection = ({ reverse, isIcon }: Props) => <>{isIcon ? <LogoIcon /> : <Logo reverse={reverse} />}</>;

export default LogoSection;
