import { City, Country, State, Timezone } from 'types/location';
import { serverApi } from '../index';

export const locationApi = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    getCity: builder.query<City, number>({ query: (id) => `/location/cities/${id}` }),
    getCities: builder.query<City[], void>({ query: (id) => `/location/cities` }),
    getCitiesByState: builder.query<City[], number>({ query: (id) => `/location/cities/state/${id}` }),
    getState: builder.query<State, number>({ query: (id) => `/location/states/${id}` }),
    getStates: builder.query<State[], void>({ query: (id) => `/location/states` }),
    getStatesByCountry: builder.query<State[], number>({ query: (id) => `/location/states/country/${id}` }),
    getCountries: builder.query<Country[], void>({ query: () => `/location/countries` }),
    getCountry: builder.query<Country, number>({ query: (id) => `/location/countries/${id}` }),
    getTimezonesByCountry: builder.query<Timezone[], number>({ query: (id) => `/location/timezones/country/${id}` }),
    getTimezone: builder.query<Timezone, number>({ query: (id) => `/location/timezones/${id}` })
  }),
  overrideExisting: false
});

export const {
  useGetCityQuery,
  useLazyGetStateQuery,
  useLazyGetCitiesByStateQuery,
  useLazyGetStatesByCountryQuery,
  useGetCountriesQuery,
  useLazyGetCityQuery,
  useLazyGetCountryQuery,
  useLazyGetTimezonesByCountryQuery,
  useLazyGetTimezoneQuery,
  useGetStateQuery,
  useGetStatesQuery,
  useGetCitiesQuery
} = locationApi;
