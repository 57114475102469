import { serverApi } from '../index';
import { Gateway, GatewayStatusesEnum } from 'types/gateway';
import { PaginatedReq, PaginatedRes } from 'types/pagination';
import { Building } from 'types/building';

const extendedApi = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    getGateways: builder.query<PaginatedRes<Gateway>, PaginatedReq>({
      query: (arg) => {
        const { filter, ...others } = arg || {};
        return {
          url: '/gateways',
          params: {
            ...others,
            ...filter
          }
        };
      },
      providesTags: (result) => (result ? [...result.data.map(({ id }) => ({ type: 'Gateway' as const, id })), 'Gateway'] : ['Gateway'])
    }),
    removeGateway: builder.mutation<void, number>({
      query: (id) => ({ url: `/gateways/${id}`, method: 'delete' }),
      invalidatesTags: (result, error, id) => [{ type: 'Gateway', id }]
    }),
    setGatewayBuilding: builder.mutation<void, Pick<Gateway, 'id' | 'building_id' | 'sim_number' | 'physical_location'>>({
      query: ({ id, ...body }) => ({ url: `/gateways/${id}`, method: 'PUT', body }),
      invalidatesTags: (_, __, { id }) => [{ type: 'Gateway', id }]
    }),
    updateDisableAlerts: builder.mutation<void, { id: number; status: 0 | 1 }>({
      query: ({ id, status }) => ({ url: `/gateways/${id}/disable-alerts/${status}`, method: 'PUT' }),
      invalidatesTags: (_, __, { id }) => [{ type: 'Gateway', id }]
    }),
    getTopGateways: builder.query<Gateway[], string>({
      query: (serial_number) => ({ url: `/gateways/search-top/${encodeURIComponent(serial_number)}/5` }),
      providesTags: (result) => (result ? [...result.map(({ id }) => ({ type: 'Gateway' as const, id })), 'Gateway'] : ['Gateway'])
    }),
    getGateway: builder.query<Gateway, number>({
      query: (id) => {
        return `/gateways/${id}`;
      },
      providesTags: (_, __, id) => [{ type: 'Gateway', id }]
    }),
    getGatewayBuildings: builder.query<(Building & { started: number })[], string>({
      query: (serial_number) => ({ url: `/gateways/${encodeURIComponent(serial_number)}/buildings-history` })
    }),
    updateGatewayStatus: builder.mutation<void, { id: number; status: GatewayStatusesEnum }>({
      query: ({ id, status }) => {
        return { url: `/gateways/${id}/status/${status}`, method: 'PUT' };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'Gateway', id }]
    })
  }),
  overrideExisting: false
});

export const {
  useGetGatewaysQuery,
  useRemoveGatewayMutation,
  useSetGatewayBuildingMutation,
  useLazyGetTopGatewaysQuery,
  useLazyGetGatewayQuery,
  useGetGatewayBuildingsQuery,
  useUpdateDisableAlertsMutation,
  useUpdateGatewayStatusMutation
} = extendedApi;
