import { dispatch } from 'store';

// Api's
import { companyApi } from 'store/reducers/api/company';
import { locationApi } from 'store/reducers/api/location';
import { propertyApi } from 'store/reducers/api/property';
import { buildingApi } from 'store/reducers/api/building';
import { unitApi } from 'store/reducers/api/unit';

// types
import { Company } from 'types/company';
import { City, Country, State } from 'types/location';
import { Property } from 'types/property';
import { Unit } from 'types/unit';
import { Building } from 'types/building';

export const getCompany = async (id: number) => {
  try {
    const result = dispatch(companyApi.endpoints.getCompany.initiate(id));
    const { data } = await result;
    result.unsubscribe();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getProperty = async (id: number) => {
  try {
    const result = dispatch(propertyApi.endpoints.getProperty.initiate(id));
    const { data } = await result;
    result.unsubscribe();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getBuilding = async (id: number) => {
  try {
    const result = dispatch(buildingApi.endpoints.getBuilding.initiate(id));
    const { data } = await result;
    result.unsubscribe();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getCity = async (id: number) => {
  try {
    const result = dispatch(locationApi.endpoints.getCity.initiate(id));
    const { data } = await result;
    result.unsubscribe();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getState = async (id: number) => {
  try {
    const result = dispatch(locationApi.endpoints.getState.initiate(id));
    const { data } = await result;
    result.unsubscribe();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getCountry = async (id: number) => {
  try {
    const result = dispatch(locationApi.endpoints.getCountry.initiate(id));
    const { data } = await result;
    result.unsubscribe();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getUnit = async (id: number) => {
  try {
    const result = dispatch(unitApi.endpoints.getUnit.initiate(id));
    const { data } = await result;
    result.unsubscribe();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getPropertyDetail = async (id: number) => {
  let property: Property | undefined;
  let state: State | undefined;
  let city: City | undefined;
  let country: Country | undefined;
  property = await getProperty(id);
  if (property) {
    city = await getCity(property.city_id);
    if (city) {
      state = await getState(city.state_id);
      if (state) {
        country = await getCountry(state.country_id);
      }
    }
  }

  return {
    property,
    city,
    state,
    country
  };
};

export const getCompanyDetail = async (id: number) => {
  let company: Company | undefined;
  let state: State | undefined;
  let city: City | undefined;
  let country: Country | undefined;
  company = await getCompany(id);
  if (company) {
    city = await getCity(company.city_id);
    if (city) {
      state = await getState(city.state_id);
      if (state) {
        country = await getCountry(state.country_id);
      }
    }
  }

  return {
    company,
    city,
    state,
    country
  };
};

export const getUnitDetail = async (id: number) => {
  let unit: Unit | undefined;
  let building: Building | undefined;
  unit = await getUnit(id);
  if (unit) {
    building = await getBuilding(unit.building_id);
  }
  return {
    unit,
    building
  };
};

export type CompanyDetail = Awaited<ReturnType<typeof getCompanyDetail>>;
export type PropertyDetail = Awaited<ReturnType<typeof getPropertyDetail>>;
export type UnitDetail = Awaited<ReturnType<typeof getUnitDetail>>;
