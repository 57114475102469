import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router';
import { FormattedMessage } from 'react-intl';

// components
import Loadable from 'components/Loadable';

// api's
import { getCompany, getPropertyDetail, PropertyDetail } from 'utils/raw-api';

// types
import { Company } from 'types/company';

// constants
import { COMPANY_ROLES, SYSTEM_ROLES } from 'constant';

// pages
const PropertyLevel = Loadable(lazy(() => import('pages/properties/property-level')));
const PropertyOverview = Loadable(lazy(() => import('pages/properties/property-overview')));
const PropertyBuildings = Loadable(lazy(() => import('pages/properties/property-buildings')));
const PropertyAlerts = Loadable(lazy(() => import('pages/properties/property-alerts')));
const PropertyMicroMeters = Loadable(lazy(() => import('pages/properties/property-micro-meters')));
const PropertySubmissions = Loadable(lazy(() => import('pages/properties/property-submissions')));
const PropertyUsers = Loadable(lazy(() => import('pages/properties/property-users')));
const PropertyCharts = Loadable(lazy(() => import('pages/properties/property-charts')));

const Routes: RouteObject = {
  path: 'companies',
  handle: {
    crumb: () => <FormattedMessage id="companies" />,
    allowedRoles: [...SYSTEM_ROLES, ...COMPANY_ROLES]
  },
  children: [
    {
      path: ':companyId',
      loader: async ({ params }) => {
        if (params.companyId) {
          return await getCompany(Number(params.companyId));
        }
      },
      handle: {
        crumb: (data?: Company) => <>{data?.name}</>
      },
      children: [
        {
          path: 'properties',
          handle: {
            crumb: () => <FormattedMessage id="properties" />
          },
          children: [
            {
              path: ':propertyId',
              element: <PropertyLevel />,
              loader: async ({ params }) => {
                if (params.propertyId) {
                  return await getPropertyDetail(Number(params.propertyId));
                }
              },
              handle: {
                crumb: ({ property }: PropertyDetail) => <>{property?.name}</>,
                title: ({ property }: PropertyDetail) => <>{property?.name}</>,
                subtitle: ({ city, state, country }: PropertyDetail) => {
                  const address = [city?.name, state?.name, country?.name].filter((x) => x).join(', ');
                  return <>{address}</>;
                }
              },
              children: [
                {
                  index: true,
                  element: <Navigate to="buildings" replace />
                },
                {
                  path: 'overview',
                  element: <PropertyOverview />,
                  handle: {
                    crumb: () => <FormattedMessage id="overview" />
                  }
                },
                {
                  path: 'buildings',
                  element: <PropertyBuildings />,
                  handle: {
                    crumb: () => <FormattedMessage id="buildings" />
                  }
                },
                {
                  path: 'alerts',
                  element: <PropertyAlerts />,
                  handle: {
                    crumb: () => <FormattedMessage id="alerts" />
                  }
                },
                {
                  path: 'micrometers',
                  element: <PropertyMicroMeters />,
                  handle: {
                    crumb: () => <FormattedMessage id="micrometers" />
                  }
                },
                {
                  path: 'submissions',
                  element: <PropertySubmissions />,
                  handle: {
                    crumb: () => <FormattedMessage id="submissions" />
                  }
                },
                {
                  path: 'users',
                  element: <PropertyUsers />,
                  handle: {
                    crumb: () => <FormattedMessage id="users" />
                  }
                },
                {
                  path: 'charts',
                  element: <PropertyCharts />,
                  handle: {
                    crumb: () => <FormattedMessage id="charts" />
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default Routes;
