import Avatar, { Props } from './@extended/Avatar';

// icon
import { UserOutlined } from '@ant-design/icons';

interface UserAvatarProps {
  size?: Props['size'];
}

function UserAvatar({ size = 'sm' }: UserAvatarProps) {
  return (
    <Avatar alt="User Avatar" size={size}>
      <UserOutlined />
    </Avatar>
  );
}

export default UserAvatar;
