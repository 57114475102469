import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

// tools & utils
import { stringify } from 'qs';
import { getToken } from 'utils';
import { getBaseUrl } from 'utils/helper';

// redux
import { logout } from '../auth';

const baseUrl = getBaseUrl();

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const token = getToken();
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
  paramsSerializer: (params) => {
    return stringify(params);
  }
});

export const baseQueryWithAuthErrorHandling: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    api.dispatch(logout());
  }
  return result;
};

export const serverApi = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithAuthErrorHandling,
  tagTypes: [
    'Company',
    'Gateway',
    'Property',
    'Sensor',
    'Building',
    'BuildingInstaller',
    'ParsedSubmission',
    'Notification',
    'CompanyApplianceThreshold',
    'Unit',
    'Appliance',
    'User',
    'UserRole',
    'SensorConfigDefault',
    'NotificationContact',
    'SensorDownlinkConfigParams',
    'SensorGallonsThreshold',
    'Event'
  ],
  endpoints: () => ({})
});
