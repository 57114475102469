import { UserRolesEnum } from 'types/auth';

export const DAY_MILLISECONDS = 60 * 60 * 24 * 1000;
export const CHART_COLORS_1 = [
  '#ff80ed',
  '#065535',
  '#ff0000',
  '#00ffff',
  '#0000ff',
  '#e6e6fa',
  '#5A7247',
  '#666666',
  '#800000',
  '#DFCFBE'
];
export const CHART_COLORS_2 = [
  'FF0000',
  '00FF00',
  '0000FF',
  'FF00FF',
  '00FFFF',
  '000000',
  '800000',
  '008000',
  '000080',
  '808000',
  '800080',
  '008080',
  '808080',
  'C00000',
  '00C000',
  '0000C0',
  'C0C000',
  'C000C0',
  '00C0C0',
  '400000',
  '004000',
  '000040',
  '404000',
  '400040',
  '004040',
  '404040',
  '200000',
  '002000',
  '000020',
  '202000',
  '200020',
  '002020',
  '202020',
  '600000',
  '006000',
  '000060',
  '606000',
  '600060',
  '006060',
  '606060',
  'A00000',
  '00A000',
  '0000A0',
  'A0A000',
  'A000A0',
  '00A0A0',
  'A0A0A0',
  'E00000',
  '00E000',
  '0000E0',
  'E0E000',
  'E000E0',
  '00E0E0',
  'E0E0E0'
];
export const CHART_COLORS_3 = [
  '#000000',
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#00FFFF',
  '#FF00FF',
  '#808080',
  '#FF8080',
  '#80FF80',
  '#8080FF',
  '#008080',
  '#800080',
  '#808000',
  '#80FFFF',
  '#FF80FF',
  '#FF0080',
  '#80FF00',
  '#0080FF',
  '#00FF80',
  '#8000FF',
  '#FF8000',
  '#000080',
  '#800000',
  '#008000',
  '#404040',
  '#FF4040',
  '#40FF40',
  '#4040FF',
  '#004040',
  '#400040',
  '#404000',
  '#804040',
  '#408040',
  '#404080',
  '#40FFFF',
  '#FF40FF',
  '#FF0040',
  '#40FF00',
  '#0040FF',
  '#FF8040',
  '#40FF80',
  '#8040FF',
  '#00FF40',
  '#4000FF',
  '#FF4000',
  '#000040',
  '#400000',
  '#004000',
  '#008040',
  '#400080',
  '#804000',
  '#80FF40',
  '#4080FF',
  '#FF4080',
  '#800040',
  '#408000',
  '#004080',
  '#808040',
  '#408080',
  '#804080',
  '#FFC0C0',
  '#C0FFC0',
  '#C0C0FF',
  '#00C0C0',
  '#C000C0',
  '#C0C000',
  '#80C0C0',
  '#C080C0',
  '#C0C080',
  '#40C0C0',
  '#C040C0',
  '#C0C040',
  '#FFFFC0',
  '#C0FFFF',
  '#FFC0FF',
  '#FF00C0',
  '#C0FF00',
  '#00C0FF',
  '#FF80C0',
  '#C0FF80',
  '#80C0FF',
  '#FF40C0',
  '#C0FF40',
  '#40C0FF',
  '#00FFC0',
  '#C000FF',
  '#FFC000',
  '#0000C0',
  '#C00000',
  '#00C000',
  '#0080C0',
  '#C00080',
  '#80C000',
  '#0040C0',
  '#C00040',
  '#40C000',
  '#80FFC0',
  '#C080FF',
  '#FFC080',
  '#8000C0',
  '#C08000',
  '#00C080',
  '#8080C0',
  '#C08080',
  '#80C080',
  '#8040C0',
  '#C08040',
  '#40C080',
  '#40FFC0',
  '#C040FF',
  '#FFC040',
  '#4000C0',
  '#C04000',
  '#00C040',
  '#4080C0',
  '#C04080',
  '#80C040',
  '#4040C0',
  '#C04040',
  '#40C040',
  '#202020',
  '#FF2020',
  '#20FF20'
];
export const SYSTEM_ROLES = [UserRolesEnum.SYSTEM_ADMIN, UserRolesEnum.SYSTEM_SUPPORT, UserRolesEnum.SYSTEM_TECHNICIAN];
export const COMPANY_ROLES = [UserRolesEnum.COMPANY_ADMIN, UserRolesEnum.COMPANY_MANAGER];
export const GALLONS_REPORT_INTERVAL_DAYS = 90;
