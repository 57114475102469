import { UserRole } from 'types/auth';

const setToken = (token?: string | null): void => {
  if (token) {
    localStorage.setItem('serviceToken', token);
  } else {
    localStorage.removeItem('serviceToken');
  }
};

const getToken = (): string | null => {
  return localStorage.getItem('serviceToken');
};

const setUserActiveRole = (role?: UserRole | null): void => {
  if (role) {
    localStorage.setItem('userActiveRole', JSON.stringify(role));
  } else {
    localStorage.removeItem('userActiveRole');
  }
};

const getUserActiveRole = (): UserRole | null => {
  const role = localStorage.getItem('userActiveRole');
  return role ? JSON.parse(role) : null;
};

export { setToken, getToken, setUserActiveRole, getUserActiveRole };
