// components
import DialogHeader from '../DialogHeader';
import UserRolesList, { UserRolesListProps } from 'components/sections/UserRolesList';

// Mui
import { Theme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, Divider, useMediaQuery } from '@mui/material';

interface UserRolesDialogProps extends Omit<DialogProps, 'onClose'> {
  onClose?: () => void;
  userRolesListProps: UserRolesListProps;
}

function UserRolesDialog({ userRolesListProps, onClose, ...others }: UserRolesDialogProps) {
  const matchDownMD = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const handleClose = () => {
    onClose?.();
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: 600, height: 400 } }}
      maxWidth={matchDownMD ? 'sm' : 'lg'}
      {...others}
      onClose={handleClose}
    >
      <DialogHeader onClose={handleClose}>User Roles</DialogHeader>
      <Divider />
      <DialogContent>
        <UserRolesList {...userRolesListProps} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" sx={{ mr: 0.5 }} onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserRolesDialog;
