import { Button, ButtonProps, DialogActions, DialogContent, Grid } from '@mui/material';
import LoadingButton from './@extended/LoadingButton';

interface DialogFormProps {
  items: JSX.Element[];
  actions?: JSX.Element[];
  onClickCancel?: () => void;
  isSubmitting?: boolean;
  okBtnLabel?: string;
  hideCancelBtn?: boolean;
  okBtnProps?: ButtonProps;
}

function DialogForm({ items, onClickCancel, isSubmitting, okBtnLabel, hideCancelBtn, actions, okBtnProps }: DialogFormProps) {
  return (
    <>
      <DialogContent>
        <Grid container spacing={1}>
          {items.map((item, index) => (
            <Grid item xs={12} key={index}>
              {item}
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        {actions}
        {!hideCancelBtn && (
          <Button color="error" autoFocus onClick={onClickCancel}>
            Cancel
          </Button>
        )}
        <LoadingButton type="submit" variant="contained" sx={{ mr: 0.5 }} disabled={isSubmitting} loading={isSubmitting} {...okBtnProps}>
          {okBtnLabel || 'Ok'}
        </LoadingButton>
      </DialogActions>
    </>
  );
}

export default DialogForm;
