import { useMemo } from 'react';

// Mui
import { AlertProps } from '@mui/material';

// redux
import { useDispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';

// types
import { SnackbarProps } from 'types/snackbar';

const useSnackbar = () => {
  const dispatch = useDispatch();

  return useMemo(() => {
    const option: Partial<SnackbarProps> = {
      open: true,
      variant: 'alert',
      close: false,
      anchorOrigin: { vertical: 'top', horizontal: 'center' }
    };
    return {
      showError: (message: string) =>
        dispatch(
          openSnackbar({
            ...option,
            message,
            alert: {
              color: 'error'
            }
          })
        ),
      show: (message: string, color: AlertProps['color']) =>
        dispatch(
          openSnackbar({
            ...option,
            message,
            alert: {
              color
            }
          })
        ),
      showSuccess: (message: string) =>
        dispatch(
          openSnackbar({
            ...option,
            message,
            alert: {
              color: 'success'
            }
          })
        ),
      showWarning: (message: string) =>
        dispatch(
          openSnackbar({
            ...option,
            message,
            alert: {
              color: 'warning'
            }
          })
        )
    };
  }, [dispatch]);
};

export default useSnackbar;
