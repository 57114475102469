import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, ButtonBase, CardContent, ClickAwayListener, Grid, Paper, Popper, Stack, Tooltip, Typography } from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import IconButton from 'components/@extended/IconButton';
import useAuth from 'hooks/useAuth';
import UserAvatar from 'components/UserAvatar';
import UserRolesDialog from 'components/dialogs/UserRolesDialog';

// assets
import { LogoutOutlined } from '@ant-design/icons';

// redux
import { useDispatch } from 'store';
import { logout, setIsInitialized } from 'store/reducers/auth';
import { useGetStaticQuery } from 'store/reducers/api/static';
import { useLazyGetNewTokenQuery } from 'store/reducers/api/auth';

// utils && hooks
import { get } from 'lodash';
import useSnackbar from 'hooks/useSnackbar';

// types
import { UserRole } from 'types/auth';
import { AnalyticsClickEvents, trackClickEvent } from '../../../../../utils/analytics';

// types
/*interface TabPanelProps {
  children?: ReactNode;
  dir?: string;
  index: number;
  value: number;
}*/

// tab panel wrapper
/*function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}*/

/*function a11yProps(index: number) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`
  };
}*/

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();
  const snackbar = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, activeRole, roles } = useAuth();

  const [openUserRolesDialog, setOpenUserRolesDialog] = useState(false);

  const { data: statics } = useGetStaticQuery();
  const [getNewToken] = useLazyGetNewTokenQuery();

  const activeRoleType = useMemo(() => get(statics, `user_roles.${activeRole?.user_role_type_id}`), [activeRole, statics]);

  const handleLogout = async () => {
    try {
      trackClickEvent(AnalyticsClickEvents.LOG_OUT, { email: user?.email });
      dispatch(logout());
    } catch (err) {
      console.error(err);
    }
  };

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  //const [value, setValue] = useState(0);

  /*const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };*/

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';

  const handleSelectRole = useCallback(
    async (userRole: UserRole) => {
      const { id, user_role_type_id } = userRole;
      try {
        dispatch(setIsInitialized(false));
        await getNewToken({ role_id: id }).unwrap();
        navigate('/', { replace: true });
        dispatch(setIsInitialized(true));

        const selectedRole = get(statics, `user_roles.${user_role_type_id}`);
        snackbar.showSuccess(`${selectedRole} was selected`);
      } catch (error) {
        console.error(error);
      }
    },
    [snackbar, statics, getNewToken, navigate, dispatch]
  );

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      {user && (
        <UserRolesDialog
          userRolesListProps={{
            userId: user.id,
            isItemButton: true,
            onSelectRole: handleSelectRole,
            showActiveRole: true,
            showSearch: true
          }}
          open={openUserRolesDialog}
          onClose={() => setOpenUserRolesDialog(false)}
        />
      )}
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter' },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <UserAvatar size="xs" />
          <Typography variant="subtitle1">
            {user?.first_name} {user?.last_name}
          </Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Stack direction="row" spacing={1.25} alignItems="center">
                            <UserAvatar />
                            <Stack>
                              <Typography variant="h6">
                                {user?.first_name} {user?.last_name}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {activeRoleType}
                              </Typography>
                              {(roles?.length || 0) > 1 && (
                                <Button size="extraSmall" onClick={() => setOpenUserRolesDialog(true)}>
                                  Switch Role
                                </Button>
                              )}
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item>
                          <Tooltip title="Logout">
                            <IconButton size="large" sx={{ color: 'text.primary' }} onClick={handleLogout}>
                              <LogoutOutlined />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {open && (
                      <>
                        {/*<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="profile tabs">
                            <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize'
                              }}
                              icon={<UserOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                              label="Profile"
                              {...a11yProps(0)}
                            />
                            <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize'
                              }}
                              icon={<SettingOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                              label="Setting"
                              {...a11yProps(1)}
                            />
                          </Tabs>
                        </Box>*/}
                        {/*<TabPanel value={value} index={0} dir={theme.direction}>
                          <ProfileTab handleLogout={handleLogout} />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                          <SettingTab />
                        </TabPanel>*/}
                      </>
                    )}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
