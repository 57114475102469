import { useMemo } from 'react';

// components
import DialogHeader from '../DialogHeader';

// types
import { Sensor } from 'types/sensor';

// redux
import { useGetStaticQuery } from 'store/reducers/api/static';
import { useGetPropertyQuery } from 'store/reducers/api/property';
import { useGetBuildingQuery } from 'store/reducers/api/building';
import { useGetCompanyQuery } from 'store/reducers/api/company';
import { useGetUnitQuery } from 'store/reducers/api/unit';

// Mui
import { Theme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// utils
import { getLocaleDateWithoutSeconds } from 'utils/helper';

// hooks
import useUserRole from 'hooks/useUserRole';
import useGetTimezone from 'hooks/useGetTimezone';

const GridItem = ({ label, value }: { label: string; value?: string | null | number }) => (
  <Grid item xs={12} sm={6}>
    <Stack spacing={0.5}>
      <Typography color="secondary">{label}</Typography>
      <Typography>{value}</Typography>
    </Stack>
  </Grid>
);

interface MicrometerDialogProps extends Omit<DialogProps, 'onClose'> {
  onClose?: () => void;
  sensor?: Sensor;
}

function MicrometerDialog({ sensor, onClose, ...others }: MicrometerDialogProps) {
  const { hasSystemRole } = useUserRole();
  const matchDownMD = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { data: dataStatic } = useGetStaticQuery();
  const { data: company } = useGetCompanyQuery(sensor?.company_id!, { skip: !sensor?.company_id });
  const { data: property } = useGetPropertyQuery(sensor?.property_id!, { skip: !sensor?.property_id });
  const { data: building } = useGetBuildingQuery(sensor?.building_id!, { skip: !sensor?.building_id });
  const { data: unit } = useGetUnitQuery(sensor?.unit_id!, { skip: !sensor?.unit_id });
  const { timezone } = useGetTimezone({ propertyId: sensor?.property_id });

  const handleClose = () => {
    onClose?.();
  };

  const dataSensor = useMemo(() => {
    if (sensor && dataStatic) {
      return {
        state: dataStatic?.['sensor_states'][sensor.sensor_state_id],
        status: dataStatic?.['sensor_statuses'][sensor.sensor_status_id],
        appliance: sensor.appliance_type_id ? dataStatic?.['appliances_types'][sensor.appliance_type_id] : undefined,
        room: sensor.room_type_id ? dataStatic?.['room_types'][sensor.room_type_id] : undefined,
        water: sensor.water_type_id ? dataStatic?.['water_types'][sensor.water_type_id] : undefined
      };
    }
  }, [sensor, dataStatic]);

  const isAssigned = useMemo(() => !!sensor?.company_id, [sensor?.company_id]);
  const sensorSize = sensor?.size_inch ? `${sensor?.size_inch}"` : undefined;

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: 600, maxHeight: 'calc(100% - 20px)' } }}
      maxWidth={matchDownMD ? 'sm' : 'lg'}
      {...others}
      onClose={handleClose}
      scroll="paper"
    >
      <DialogHeader onClose={handleClose}>Micrometer Details</DialogHeader>
      <Divider />
      <DialogContent>
        <Grid container spacing={3}>
          <GridItem label="MAC Address" value={sensor?.mac_address} />
          <GridItem label="State" value={dataSensor?.state} />
          <GridItem label="Status" value={dataSensor?.status} />
          <GridItem label="Battery" value={sensor?.battery} />
          <GridItem label="FW version" value={sensor?.firmware_version} />
          {sensorSize && <GridItem label="Size" value={sensorSize} />}
          <GridItem label="Production Date" value={getLocaleDate(sensor?.install_date, timezone)} />
          <GridItem label="Last keep-alive" value={getLocaleDate(sensor?.last_keepalive, timezone)} />
          <GridItem label="Last Submission" value={getLocaleDate(sensor?.last_submission, timezone)} />
          {isAssigned && (
            <>
              <GridItem label="Company" value={company?.name} />
              <GridItem label="Property" value={property?.name} />
              <GridItem label="Building" value={building?.name} />
              <GridItem label="Unit" value={unit?.name} />
              <GridItem label="Fixture" value={dataSensor?.appliance} />
              <GridItem label="Room" value={dataSensor?.room} />
              <GridItem label="Water" value={dataSensor?.water} />
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {hasSystemRole && (
          <>
            <Button
              type="submit"
              variant="contained"
              href={`/micrometers/assignment?mac=${sensor?.mac_address}${isAssigned ? '&reload=1' : ''}`}
            >
              {isAssigned ? 'Reassign' : 'Assign'}
            </Button>
            <Button type="submit" variant="contained" href={`/micrometers/config?mac=${sensor?.mac_address}`}>
              Config
            </Button>
          </>
        )}
        {isAssigned && (
          <Button
            type="submit"
            variant="contained"
            href={`/companies/${sensor?.company_id}/properties/${sensor?.property_id}/buildings/${sensor?.building_id}/units/${sensor?.unit_id}/submissions?mac=${sensor?.mac_address}`}
          >
            Submissions
          </Button>
        )}
        <Button
          type="submit"
          variant="contained"
          href={
            isAssigned
              ? `/companies/${sensor?.company_id}/properties/${sensor?.property_id}/buildings/${sensor?.building_id}/units/${sensor?.unit_id}/micrometers?mac=${sensor?.mac_address}`
              : `/micrometers/unassigned?mac=${sensor?.mac_address}`
          }
        >
          MicroMeter
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MicrometerDialog;

const getLocaleDate = (date?: string | null, timezone?: string): string | undefined =>
  date ? getLocaleDateWithoutSeconds(date, timezone) : undefined;
