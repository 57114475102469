import { useEffect, useMemo, useState } from 'react';

// material-ui
import { Box, debounce, FormControl, IconButton, InputAdornment, Link, TextField } from '@mui/material';

// icons
import { SearchOutlined } from '@ant-design/icons';

// components
import MicrometerDialog from 'components/dialogs/MicrometerDialog';
import AutoComplete from 'components/Autocomplete';
import ExportToCsvDialog from 'components/dialogs/ExportToCsvDialog';

// redux
import { useLazyGetTopCompaniesQuery } from 'store/reducers/api/company';
import { useLazyGetPropertyQuery, useLazyGetTopPropertiesQuery } from 'store/reducers/api/property';
import { useLazyGetBuildingQuery, useLazyGetTopBuildingsQuery } from 'store/reducers/api/building';
import { useLazyGetTopUnitsQuery } from 'store/reducers/api/unit';
import { useLazyGetTopSensorsByMacQuery } from 'store/reducers/api/sensor';
import { useLazyGetTopGatewaysQuery } from 'store/reducers/api/gateway';
import { useLazyGetTopUserQuery } from 'store/reducers/api/user';

// utils
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { get } from 'lodash';

// types
import { Sensor } from 'types/sensor';

// hooks
import useUserRole from 'hooks/useUserRole';
import TrackedButton from '../../../../components/Buttons/TrackedButton';
import { AnalyticsClickEvents } from '../../../../utils/analytics';

async function buildPromise<T>(
  promiseFunc: () => Promise<{ data?: T[] }>,
  groupLabel: string,
  toPath: (input: T) => string | Promise<string | undefined> | undefined,
  toName?: (input: T) => string,
  others?: (input: T) => any
) {
  const { data } = await promiseFunc();

  return {
    res: data?.map(async (x: T) => {
      const path = (await toPath(x)) || '';
      const name = get(x, 'name') || toName?.(x) || '';

      return {
        ...x,
        groupLabel,
        to: path,
        name,
        ...others?.(x)
      };
    })
  };
}

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

type SearchItem = { id: number; name: string; groupLabel: string; to: string; sensor?: Sensor };

const Search = () => {
  const { hasSystemRole, hasCompanyRole } = useUserRole();

  const [selectedSensor, setSelectedSensor] = useState<Sensor>();
  const [openDialog, setOpenDialog] = useState(false);
  const [openExportToCsvDialog, setOpenExportToCsvDialog] = useState(false);

  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<SearchItem | null>(null);
  const [options, setOptions] = useState<readonly SearchItem[]>([]);

  const [getTopCompanies] = useLazyGetTopCompaniesQuery();
  const [getTopProperties] = useLazyGetTopPropertiesQuery();
  const [getTopBuildings] = useLazyGetTopBuildingsQuery();
  const [getTopUnits] = useLazyGetTopUnitsQuery();
  const [getTopSensors] = useLazyGetTopSensorsByMacQuery();
  const [getTopGateways] = useLazyGetTopGatewaysQuery();
  const [getTopUsers] = useLazyGetTopUserQuery();

  const [getProperty] = useLazyGetPropertyQuery();
  const [getBuilding] = useLazyGetBuildingQuery();

  const fetch = useMemo(
    () =>
      debounce((input: string, callback: (results?: readonly SearchItem[]) => void) => {
        const promises = [
          buildPromise(
            () => getTopSensors(input),
            'MicroMeter',
            () => '',
            (x) => x.mac_address!,
            (x) => ({
              sensor: x
            })
          )
        ];

        if (hasSystemRole || hasCompanyRole) {
          promises.push(
            ...[
              buildPromise(
                () => getTopCompanies(input),
                'Company',
                (x) => `/companies/${x.id}`
              ),
              buildPromise(
                () => getTopProperties(input),
                'Property',
                (x) => `/companies/${x.company_id}/properties/${x.id}`
              ),
              buildPromise(
                () => getTopBuildings(input),
                'Building',
                async (x) => {
                  const { data: property } = await getProperty(x.property_id);

                  return `/companies/${property?.company_id}/properties/${x.property_id}/buildings/${x.id}`;
                }
              ),
              buildPromise(
                () => getTopUnits(input),
                'Unit',
                async (x) => {
                  const { data: building } = await getBuilding(x.building_id);

                  if (building) {
                    const { data: property } = await getProperty(building.property_id);

                    return `/companies/${property?.company_id}/properties/${property?.id}/buildings/${x.building_id}/units/${x.id}`;
                  }
                }
              ),
              buildPromise(
                () => getTopUsers(input),
                'User',
                () => `/users?filter.user_name=${encodeURIComponent(`$ilike:${input}`)}`,
                (x) => `${x.first_name} ${x.last_name}`
              )
            ]
          );
        }
        if (hasSystemRole) {
          promises.push(
            buildPromise(
              () => getTopGateways(input),
              'Gateway',
              (x) => `/gateways?serial_number=${x.serial_number}`,
              (x) => x.serial_number
            ) as Promise<{ res: any }>
          );
        }

        Promise.all(promises).then(async (res) => {
          const results = [];
          for (const result of res) {
            if (result.res) {
              const nested_res = await Promise.all(result.res).then();
              for (const _res of nested_res) {
                if (_res) {
                  results.push(_res);
                }
              }
            }
          }

          callback(results);
        });
      }, 400),
    [
      getTopCompanies,
      getTopProperties,
      getTopBuildings,
      getTopUnits,
      getTopSensors,
      getBuilding,
      getProperty,
      getTopGateways,
      getTopUsers,
      hasSystemRole,
      hasCompanyRole
    ]
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(inputValue, (results?: readonly SearchItem[]) => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
      <FormControl sx={{ width: { xs: '100%', md: 224 } }}>
        <AutoComplete
          fullWidth
          getOptionLabel={(option) => option.name}
          getOptionKey={(option) => option.id}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          autoHighlight
          includeInputInList
          filterSelectedOptions
          value={value}
          onChange={(_: any, newValue: SearchItem | null) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
          }}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue);
          }}
          groupBy={(option) => option.groupLabel}
          popupIcon={''}
          noOptionsText=""
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ padding: 0 }}
              size="small"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start" sx={{ mr: -0.5 }}>
                    <IconButton size="small">
                      <SearchOutlined style={{ fontSize: 'inherit' }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              placeholder="Type to search..."
            />
          )}
          renderOption={(props, option) => {
            const matches = match(option.name, inputValue, { insideWords: true });
            const parts = parse(option.name, matches);
            return (
              <li {...props} key={option.id} style={{ padding: 0 }}>
                <Link
                  href={option.to}
                  onClick={(e) => {
                    if (!option.to) {
                      e.preventDefault();
                      setSelectedSensor(option.sensor);
                      setOpenDialog(true);
                    }
                  }}
                  sx={{ width: '100%', display: 'block', padding: '6px 24px' }}
                >
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Link>
              </li>
            );
          }}
        />
      </FormControl>
      {hasSystemRole && (
        <>
          <ExportToCsvDialog open={openExportToCsvDialog} onClose={() => setOpenExportToCsvDialog(false)} />
          <TrackedButton
            trackId={AnalyticsClickEvents.EXPORT_ALL}
            onClick={() => setOpenExportToCsvDialog(true)}
            variant="contained"
            sx={{ ml: { md: 1 }, mt: { xs: 1, md: 0 } }}
          >
            Export All to CSV
          </TrackedButton>
        </>
      )}
      <MicrometerDialog open={openDialog} onClose={() => setOpenDialog(false)} sensor={selectedSensor} />
    </Box>
  );
};

export default Search;
