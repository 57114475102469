import uniq from 'lodash/uniq';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { serverApi } from '../index';

// types
import { ForgotPasswordReq, LoginReq, LoginRes, ResetForgotPasswordReq } from 'types/login';
import { AnalyticsAuthEvents, setUser as setAnalyticsUser, trackAnalyticsEvent } from '../../../../utils/analytics';
import { UserRole } from '../../../../types/auth';

const handleResult = (result: QueryReturnValue<unknown, FetchBaseQueryError, {}>) => {
  const customErrorData = {
    error: 'Unauthorized',
    message: 'Username or password is not valid',
    statusCode: 401
  };

  if (result.data) {
    const res = result.data as LoginRes;
    if (res.roles[0].user_role_type_id > 5 && res.roles[0].user_role_type_id !== 12) {
      return {
        error: {
          data: customErrorData,
          status: 401
        } as FetchBaseQueryError
      };
    }
    const data = result.data as LoginRes;
    const companies = getCompaniesFromRole(data.roles || []);
    setAnalyticsUser(`${data.user.id}`, companies, data.user.email, `${data.user.first_name} ${data.user.last_name}`);
    return { data };
  } else {
    return { error: result.error as FetchBaseQueryError };
  }
};

const getCompaniesFromRole = (roles: UserRole[]): string[] => {
  const companies: string[] = [];
  roles.forEach((r) => {
    companies.push(`${r.company_id || 'admin'}`);
  });
  return uniq(companies);
};

export const authApi = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginRes, LoginReq>({
      queryFn: async (body, _queryApi, _extraOptions, fetchWithBQ) => {
        const result = await fetchWithBQ({ url: '/auth/login', body, method: 'POST' });
        const loginResult = result.data as LoginRes;
        trackAnalyticsEvent(AnalyticsAuthEvents.LOG_IN, { email: loginResult.user.email });
        return handleResult(result);
      }
    }),
    me: builder.query<Omit<LoginRes, 'access_token'>, void>({
      queryFn: async (_args, _queryApi, _extraOptions, fetchWithBQ) => {
        const result = await fetchWithBQ({ url: '/auth/me' });
        return handleResult(result);
      }
    }),
    getNewToken: builder.query<Partial<LoginRes>, { role_id: number }>({
      queryFn: async (_args, _queryApi, _extraOptions, fetchWithBQ) => {
        const result = await fetchWithBQ({ url: `/auth/new-token/${_args.role_id}` });
        return handleResult(result);
      }
    }),
    sendForgotPasswordEmail: builder.mutation<void, ForgotPasswordReq>({
      query: (body) => ({ url: '/auth/forgot-password-email', body, method: 'POST' })
    }),
    resetForgotPassword: builder.mutation<void, ResetForgotPasswordReq>({
      query: (body) => ({ url: '/auth/forgot-password-reset', body, method: 'POST' })
    })
  }),
  overrideExisting: false
});

export const {
  useLoginMutation,
  useMeQuery,
  useLazyMeQuery,
  useLazyGetNewTokenQuery,
  useResetForgotPasswordMutation,
  useSendForgotPasswordEmailMutation
} = authApi;
