import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackAnalyticsScreenChangedEvent } from '../utils/analytics';

export const usePageNavigation = (): void => {
  const location = useLocation();

  useEffect(() => {
    const fullUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;
    trackAnalyticsScreenChangedEvent(fullUrl);
  }, [location]);
};
