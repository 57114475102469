import { serverApi } from '../index';
import { PaginatedReq, PaginatedRes } from 'types/pagination';
import { Sensor, SensorByEntityFilter } from 'types/sensor';

// types
import { KeySetPaginationReq, KeySetPaginationRes } from 'types/key-set-pagination';
import { Notification } from 'types/notification';
import { Parsed } from 'types/submission';
import { Unit, UnitCategory, UnitGallonsCountReq, UnitGallonsCountRes, UnitState, UnitType } from 'types/unit';
import { EntityOpenAlertsCount } from 'types/entity-open-alerts-count';
import { TimeIntervalEnum } from 'types/time-interval.enum';

export const unitApi = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    getUnit: builder.query<Unit, number>({ query: (id) => `/units/${id}`, providesTags: (_, __, id) => [{ type: 'Unit', id }] }),
    getUnitOpenAlertsCount: builder.query<EntityOpenAlertsCount, number>({
      query: (id) => `/units/${id}/open-alerts-count`,
      providesTags: ['Notification']
    }),
    getUnitGallonsCount: builder.query<UnitGallonsCountRes, { id: number; start_date?: string | number; end_date?: string | number }>({
      query: ({ id, start_date, end_date }) => ({
        url: `/units/${id}/gallons-count`,
        params: { start_date, end_date }
      })
    }),
    getUnitSensorsCount: builder.query<
      {
        unit_id: number;
        water_points_count: number;
        installed_sensors_count: { total: number; active: number; inactive: number };
        virtual_sensors_count: number;
        initial_sensors_count: number;
      },
      number
    >({
      query: (id) => `/units/${id}/sensors-count`
    }),
    removeUnit: builder.mutation<void, number>({
      query: (id) => ({ url: `/units/${id}`, method: 'delete' }),
      invalidatesTags: (_, __, id) => [{ type: 'Unit', id }]
    }),
    createUnit: builder.mutation<void, Omit<Unit, 'created' | 'modified' | 'id'>>({
      query: (body) => ({ url: '/units', method: 'POST', body }),
      invalidatesTags: ['Unit']
    }),
    updateUnit: builder.mutation<void, Omit<Unit, 'created' | 'modified' | 'building_id'>>({
      query: (data) => {
        const { id, ...body } = data;
        return { url: `/units/${id}`, method: 'PUT', body };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'Unit', id }]
    }),
    updateUnitTenants: builder.mutation<void, Pick<Unit, 'id' | 'tenants_kids_count' | 'tenants_adults_count'>>({
      query: (data) => {
        const { id, ...body } = data;
        return { url: `/units/${id}/tenants`, method: 'PUT', body };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'Unit', id }]
    }),
    getAlertsByUnit: builder.query<KeySetPaginationRes<Notification>, { id: number } & KeySetPaginationReq>({
      query: ({ id, ...arg }) => {
        return { url: `/units/${id}/alerts`, params: arg || undefined };
      },
      providesTags: (result) =>
        result ? [...result.docs.map(({ _id }) => ({ type: 'Notification' as const, _id })), 'Notification'] : ['Notification']
    }),
    getAlertsByUnitCount: builder.query<{ total_count: number }, { id: number; filter: KeySetPaginationReq['filter'] }>({
      query: ({ id, ...arg }) => {
        return { url: `/units/${id}/alerts/count`, params: arg || undefined };
      },
      providesTags: ['Notification']
    }),
    getUnitGallonsByAppliance: builder.query<{ appliance: string; gallons: number }[], { id: number; from: number; to: number }>({
      query: ({ id, from, to }) => `/units/${id}/appliance-gallons?from=${from}&to=${to}`
    }),
    getUnitGallons: builder.query<
      { date: string; gallons: number }[],
      { id: number; from: number; to: number; ptpd: number; timeInterval: TimeIntervalEnum; timeZone: string }
    >({
      query: ({ id, ...args }) => ({ url: `/units/${id}/gallons`, params: args })
    }),
    getUnitStatistics: builder.query<Record<string, any>, number>({ query: (id) => `/units/${id}/statistics` }),
    getSensorsByUnit: builder.query<PaginatedRes<Sensor>, SensorByEntityFilter & PaginatedReq>({
      query: ({ id, ...arg }) => {
        const { filter, ...others } = arg;
        return {
          url: `/units/${id}/sensors`,
          params: {
            ...others,
            ...filter
          }
        };
      },
      providesTags: (result) => (result ? [...result.data.map(({ id }) => ({ type: 'Sensor' as const, id })), 'Sensor'] : ['Sensor'])
    }),
    getSubmissionsByUnit: builder.query<KeySetPaginationRes<Parsed>, { id: number; interval?: number } & KeySetPaginationReq>({
      query: ({ id, ...arg }) => {
        return { url: `/units/${id}/submissions`, params: arg || undefined };
      },
      providesTags: (result) =>
        result ? [...result.docs.map(({ _id }) => ({ type: 'ParsedSubmission' as const, _id })), 'ParsedSubmission'] : ['ParsedSubmission']
    }),
    getSubmissionsByUnitCount: builder.query<{ total_count: number }, { filter?: KeySetPaginationReq['filter']; id: number }>({
      query: ({ id, ...arg }) => {
        return { url: `/units/${id}/submissions/count`, params: arg || undefined };
      }
    }),
    getUnitApplianceGallonsCount: builder.query<UnitGallonsCountRes, UnitGallonsCountReq>({
      query: ({ unitId, applianceId, start_date, end_date }) => ({
        url: `/units/${unitId}/gallons-count/${applianceId}`,
        params: { start_date, end_date }
      })
    }),
    getUnitApplianceSensorGallonsCount: builder.query<UnitGallonsCountRes, UnitGallonsCountReq & { sensor_mac: string }>({
      query: ({ unitId, applianceId, start_date, end_date, sensor_mac }) => ({
        url: `/units/${unitId}/gallons-count/${applianceId}/${encodeURIComponent(sensor_mac)}`,
        params: { start_date, end_date }
      })
    }),
    getUnitApplianceOpenAlertsCount: builder.query<
      { unit_id: number; open_alerts_count: number },
      { unitId: number; applianceId: number; sensorMac?: string | null }
    >({
      query: ({ unitId, applianceId, sensorMac }) =>
        `/units/${unitId}/open-alerts-count/${[applianceId, sensorMac].filter(Boolean).join('/')}`,
      providesTags: ['Notification']
    }),
    getSubmissionsGallonsCountByUnit: builder.query<{ gallons_count: number }, { id: number } & KeySetPaginationReq>({
      query: ({ id, ...arg }) => {
        return { url: `/units/${id}/submissions-gallons-count`, params: arg || undefined };
      },
      providesTags: ['ParsedSubmission']
    }),
    getTopUnits: builder.query<Unit[], string>({
      query: (name) => ({ url: `/units/search-top/${encodeURIComponent(name)}/5` }),
      providesTags: (result) => (result ? [...result.map(({ id }) => ({ type: 'Unit' as const, id })), 'Unit'] : ['Unit'])
    }),
    getUnitFirstOrLastSubmission: builder.query<number, { id: number; mode: 'first' | 'last' }>({
      query: ({ id, mode }) => `/units/${id}/submissions/${mode}`
    }),
    getUnitCategories: builder.query<UnitCategory[], void>({
      query: () => `/units/categories`
    }),
    getUnitTypes: builder.query<UnitType[], void>({
      query: () => `/units/types`
    }),
    getUnitStates: builder.query<UnitState[], void>({
      query: () => `/units/states`
    })
  }),

  overrideExisting: false
});

export const {
  useGetUnitQuery,
  useGetUnitOpenAlertsCountQuery,
  useGetUnitGallonsCountQuery,
  useGetUnitSensorsCountQuery,
  useRemoveUnitMutation,
  useLazyGetUnitQuery,
  useCreateUnitMutation,
  useUpdateUnitMutation,
  useGetAlertsByUnitQuery,
  useGetUnitGallonsByApplianceQuery,
  useGetUnitStatisticsQuery,
  useGetSensorsByUnitQuery,
  useGetSubmissionsByUnitQuery,
  useGetUnitApplianceGallonsCountQuery,
  useLazyGetUnitOpenAlertsCountQuery,
  useLazyGetUnitApplianceOpenAlertsCountQuery,
  useGetSubmissionsGallonsCountByUnitQuery,
  useGetSubmissionsByUnitCountQuery,
  useLazyGetTopUnitsQuery,
  useGetUnitApplianceSensorGallonsCountQuery,
  useGetUnitFirstOrLastSubmissionQuery,
  useLazyGetUnitGallonsQuery,
  useUpdateUnitTenantsMutation,
  useGetAlertsByUnitCountQuery,
  useGetUnitCategoriesQuery,
  useGetUnitTypesQuery,
  useGetUnitStatesQuery
} = unitApi;
