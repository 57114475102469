import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router';
import { FormattedMessage } from 'react-intl';

// components
import Loadable from 'components/Loadable';

// api's
import { getBuilding, getCompany, getProperty } from 'utils/raw-api';

// types
import { Company } from 'types/company';
import { Building, BuildingSurveyStatusesEnum } from 'types/building';
import { Property } from 'types/property';

// constants
import { COMPANY_ROLES, SYSTEM_ROLES } from 'constant';

// Mui
import { Chip } from '@mui/material';

// pages
const BuildingLevel = Loadable(lazy(() => import('pages/buildings/building-level')));
const BuildingOverview = Loadable(lazy(() => import('pages/buildings/building-overview')));
const BuildingAlerts = Loadable(lazy(() => import('pages/buildings/building-alerts')));
const BuildingMicroMeters = Loadable(lazy(() => import('pages/buildings/building-micro-meters')));
const BuildingCharts = Loadable(lazy(() => import('pages/buildings/building-charts')));
const BuildingUnits = Loadable(lazy(() => import('pages/buildings/building-units')));
const BuildingSubmissions = Loadable(lazy(() => import('pages/buildings/building-submissions')));

const Routes: RouteObject = {
  path: 'companies',
  handle: {
    crumb: () => <FormattedMessage id="companies" />,
    allowedRoles: [...SYSTEM_ROLES, ...COMPANY_ROLES]
  },
  children: [
    {
      path: ':companyId',
      loader: async ({ params }) => {
        if (params.companyId) {
          return await getCompany(Number(params.companyId));
        }
      },
      handle: {
        crumb: (data?: Company) => <>{data?.name}</>
      },
      children: [
        {
          path: 'properties',
          handle: {
            crumb: () => <FormattedMessage id="properties" />
          },
          children: [
            {
              path: ':propertyId',
              loader: async ({ params }) => {
                if (params.propertyId) {
                  return await getProperty(Number(params.propertyId));
                }
              },
              handle: {
                crumb: (data?: Property) => <>{data?.name}</>
              },
              children: [
                {
                  path: 'buildings',
                  handle: {
                    crumb: () => <FormattedMessage id="buildings" />
                  },
                  children: [
                    {
                      path: ':buildingId',
                      element: <BuildingLevel />,
                      loader: async ({ params }) => {
                        if (params.buildingId) {
                          return await getBuilding(Number(params.buildingId));
                        }
                      },
                      handle: {
                        crumb: (data?: Building) => <>{data?.name}</>,
                        title: (data?: Building) => {
                          const surveyStatus: Record<number, string> = {
                            [BuildingSurveyStatusesEnum.IN_PROGRESS]: 'In Survey',
                            [BuildingSurveyStatusesEnum.APPROVED_CUSTOMER]: 'Survey approved by customer'
                          };

                          return (
                            <>
                              {data?.name}{' '}
                              {!!data?.survey_status && surveyStatus[data.survey_status] && (
                                <Chip
                                  label={surveyStatus[data.survey_status]}
                                  size="small"
                                  variant="light"
                                  sx={{ backgroundColor: 'orange', color: 'white' }}
                                />
                              )}
                            </>
                          );
                        },
                        subtitle: (building: Building | null | undefined) => {
                          const { street, postal } = building || {};
                          const address = [street, postal].filter((x) => x).join(', ');
                          return <>{address}</>;
                        }
                      },
                      children: [
                        {
                          path: '',
                          element: <Navigate to="units" replace />
                        },
                        {
                          path: 'overview',
                          element: <BuildingOverview />,
                          handle: {
                            crumb: () => <FormattedMessage id="overview" />
                          }
                        },
                        {
                          path: 'units',
                          element: <BuildingUnits />,
                          handle: {
                            crumb: () => <FormattedMessage id="units" />
                          }
                        },
                        {
                          path: 'alerts',
                          element: <BuildingAlerts />,
                          handle: {
                            crumb: () => <FormattedMessage id="alerts" />
                          }
                        },
                        {
                          path: 'units',
                          element: <BuildingUnits />,
                          handle: {
                            crumb: () => <FormattedMessage id="units" />
                          }
                        },
                        {
                          path: 'micrometers',
                          element: <BuildingMicroMeters />,
                          handle: {
                            crumb: () => <FormattedMessage id="micro-meters" />
                          }
                        },
                        {
                          path: 'charts',
                          element: <BuildingCharts />,
                          handle: {
                            crumb: () => <FormattedMessage id="charts" />
                          }
                        },
                        {
                          path: 'submissions',
                          element: <BuildingSubmissions />,
                          handle: {
                            crumb: () => <FormattedMessage id="submissions" />
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default Routes;
