import mixpanel from 'mixpanel-browser';
import { EnvName, isProd } from './enviroment';

//================================= Events ==============================
export enum AnalyticsClickEvents {
  LOG_IN = 'login',
  LOG_OUT = 'Logout',
  EXPORT_ALL = 'export_all'
}

export enum AnalyticsAuthEvents {
  LOG_IN = 'Log In',
  LOG_OUT = 'Log out'
}

export enum AnalyticsRoutesEvents {
  SCREEN_VIEW = 'Screen View'
}

export type AnalyticsEvents = AnalyticsClickEvents | AnalyticsAuthEvents | AnalyticsRoutesEvents;

//================================= Implementation ==============================
let sessionContext: any = {
  page: '',
  pageEntered: 0,
  sessionsStart: Date.now()
};
const useAnalytics = isProd;

export const initAnalytics = (): void => {
  const MIXPANEL_TOKEN = '842ec0c809a53983774c120cb57a1ff8';

  if (useAnalytics) {
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: false,
      track_pageview: false, // To avoid duplicate events, We will track the routing ourselves
      persistence: 'localStorage'
    });
  }
};

export const setUser = (userId: string, companies: string[], email: string, name: string): void => {
  if (!useAnalytics) {
    return;
  }

  // We want to avoid conflicts with ids. Since We will check for specific env and add it to the userID.
  mixpanel.identify(`${EnvName}.${userId}`);
  mixpanel.people.set({
    $email: email,
    $name: name,
    companies
    // Add anything else about the user here
  });
};

export const clearUser = (): void => {
  if (useAnalytics) {
    mixpanel.reset();
  }
  sessionContext = {};
};

export const setSessionContext = (params: { email?: string }) => {
  sessionContext = { ...sessionContext, ...(params || {}) };
};

const trackEvent = (eventId: AnalyticsEvents | string, data?: any) => {
  if (!useAnalytics) {
    console.log(`EVENT: ${eventId} data: ${JSON.stringify(data)}`);
    return;
  }
  data = data || {};
  data['durationSec'] = data['durationSec'] || (sessionContext.pageEntered > 0 ? (Date.now() - sessionContext.pageEntered) / 1000 : 0);
  try {
    // mixpanel
    mixpanel.track(eventId, {
      ...sessionContext,
      ...(data || {})
    });
  } catch (e) {
    console.error(e, 'error sending event');
  }
};

export const trackAnalyticsEvent = (eventId: AnalyticsEvents, data?: object) => {
  trackEvent(eventId, data);
};

export const trackAnalyticsScreenChangedEvent = (fullUrl: string) => {
  const now = Date.now();
  const durationSec = sessionContext.pageEntered > 0 ? (now - sessionContext.pageEntered) / 1000 : 0;
  const previousPage = sessionContext.page;
  sessionContext.pageEntered = now;
  sessionContext.page = fullUrl;
  trackAnalyticsEvent(AnalyticsRoutesEvents.SCREEN_VIEW, { page: fullUrl, previousPage, durationSec });
};

export const trackClickEvent = (eventId: AnalyticsClickEvents, data?: object) => {
  trackEvent(`click_${eventId}`, data);
};

export interface TrackedComponentProps {
  trackId?: AnalyticsClickEvents;
  trackData?: object;
}
