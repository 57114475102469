import { useEffect, useMemo } from 'react';
import { createSearchParams, useLocation, useMatches, useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';

// redux
import { useDispatch } from 'store';

// utils
import { getDefaultPath } from 'utils/helper';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, activeRole } = useAuth();
  const navigate = useNavigate();
  const { pathname, key, search } = useLocation();
  const matches = useMatches();
  const dispatch = useDispatch();

  const isAuthorized = useMemo(() => {
    const match = [...matches].reverse().find((x) => (x.handle as any)?.allowedRoles);
    const handle = match ? match.handle : undefined;
    const allowedRoles = handle ? ((handle as any).allowedRoles as number[]) : undefined;

    return activeRole?.user_role_type_id ? allowedRoles?.includes(activeRole.user_role_type_id) || false : false;
  }, [matches, activeRole]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(
        { pathname: 'login', search: key === 'default' ? `?${createSearchParams({ returnUrl: pathname, search })}` : undefined },
        { replace: true }
      );
    } else if (!isAuthorized && activeRole) {
      const defaultPath = getDefaultPath(activeRole.user_role_type_id);
      navigate({ pathname: defaultPath }, { replace: true });
    }
  }, [isLoggedIn, navigate, key, pathname, search, isAuthorized, dispatch, activeRole]);

  return children;
};

export default AuthGuard;
