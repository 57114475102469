import { authApi } from './api/auth';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// utils
import { getUserActiveRole, setToken, setUserActiveRole } from 'utils';
// types
import { AuthState } from 'types/auth';
import { AnalyticsAuthEvents, clearUser as clearAnalyticsUser, trackAnalyticsEvent } from '../../utils/analytics';

const initialState: AuthState = {
  isLoggedIn: false,
  isInitialized: undefined,
  user: null
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      trackAnalyticsEvent(AnalyticsAuthEvents.LOG_OUT, { email: state.user?.email });
      state.isLoggedIn = false;
      state.isInitialized = true;
      state.token = null;
      clearAnalyticsUser();
      setToken(null);
      setUserActiveRole(null);
    },
    setIsInitialized: (state, action: PayloadAction<boolean>) => {
      state.isInitialized = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
        const { access_token, user, roles } = payload;
        const activeRole = roles[0];

        state.isLoggedIn = true;
        state.isInitialized = true;
        state.user = user;
        state.roles = roles;
        state.activeRole = activeRole;
        setToken(access_token);
      })
      .addMatcher(authApi.endpoints.me.matchFulfilled, (state, { payload }) => {
        const { user, roles } = payload;
        const activeRole = roles[0];

        state.isLoggedIn = true;
        state.isInitialized = true;
        state.user = user;
        state.roles = roles;
        state.activeRole = getUserActiveRole() ?? activeRole;
      })
      .addMatcher(authApi.endpoints.getNewToken.matchFulfilled, (state, action) => {
        const { access_token, roles } = action.payload;
        const { role_id } = action.meta.arg.originalArgs;
        const activeRole = roles?.find((x) => x.id === role_id);

        state.activeRole = activeRole;
        state.roles = roles;

        setToken(access_token);
        setUserActiveRole(activeRole);
      });
  }
});
export const { logout, setIsInitialized } = slice.actions;

export default slice.reducer;
