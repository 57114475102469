import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router';
import { FormattedMessage } from 'react-intl';

// components
import Loadable from 'components/Loadable';

// api's
import { getBuilding, getCompany, getProperty, getUnitDetail, UnitDetail } from 'utils/raw-api';

// types
import { Company } from 'types/company';
import { Building } from 'types/building';
import { Property } from 'types/property';

// constants
import { COMPANY_ROLES, SYSTEM_ROLES } from 'constant';

// pages
const UnitLevel = Loadable(lazy(() => import('pages/units/unit-level')));
const UnitOverview = Loadable(lazy(() => import('pages/units/unit-overview')));
const UnitAlerts = Loadable(lazy(() => import('pages/units/unit-alerts')));
const UnitMicroMeters = Loadable(lazy(() => import('pages/units/unit-micro-meters')));
const UnitCharts = Loadable(lazy(() => import('pages/units/unit-charts')));
const UnitSubmissions = Loadable(lazy(() => import('pages/units/unit-submissions')));

const Routes: RouteObject = {
  path: 'companies',
  handle: {
    crumb: () => <FormattedMessage id="companies" />,
    allowedRoles: [...SYSTEM_ROLES, ...COMPANY_ROLES]
  },
  children: [
    {
      path: ':companyId',
      loader: async ({ params }) => {
        if (params.companyId) {
          return await getCompany(Number(params.companyId));
        }
      },
      handle: {
        crumb: (data?: Company) => <>{data?.name}</>
      },
      children: [
        {
          path: 'properties',
          handle: {
            crumb: () => <FormattedMessage id="properties" />
          },
          children: [
            {
              path: ':propertyId',
              loader: async ({ params }) => {
                if (params.propertyId) {
                  return await getProperty(Number(params.propertyId));
                }
              },
              handle: {
                crumb: (data?: Property) => <>{data?.name}</>
              },
              children: [
                {
                  path: 'buildings',
                  handle: {
                    crumb: () => <FormattedMessage id="buildings" />
                  },
                  children: [
                    {
                      path: ':buildingId',
                      loader: async ({ params }) => {
                        if (params.buildingId) {
                          return await getBuilding(Number(params.buildingId));
                        }
                      },
                      handle: {
                        crumb: (data?: Building) => <>{data?.name}</>
                      },
                      children: [
                        {
                          path: 'units',
                          handle: {
                            crumb: () => <FormattedMessage id="units" />
                          },
                          children: [
                            {
                              path: ':unitId',
                              element: <UnitLevel />,
                              loader: async ({ params }) => {
                                if (params.unitId) {
                                  return await getUnitDetail(Number(params.unitId));
                                }
                              },
                              handle: {
                                crumb: ({ unit }: UnitDetail) => <>{unit?.name}</>,
                                title: ({ unit }: UnitDetail) => <>{unit?.name}</>,
                                subtitle: ({ building }: UnitDetail) => {
                                  const { postal, street } = building || {};
                                  const address = [street, postal].filter((x) => x).join(', ');
                                  return <>{address}</>;
                                }
                              },
                              children: [
                                {
                                  index: true,
                                  element: <Navigate to="micrometers" replace />
                                },
                                {
                                  path: 'alerts',
                                  element: <UnitAlerts />,
                                  handle: {
                                    crumb: () => <FormattedMessage id="alerts" />
                                  }
                                },
                                {
                                  path: 'micrometers',
                                  element: <UnitMicroMeters />,
                                  handle: {
                                    crumb: () => <FormattedMessage id="micrometers" />
                                  }
                                },
                                {
                                  path: 'submissions',
                                  element: <UnitSubmissions />,
                                  handle: {
                                    crumb: () => <FormattedMessage id="submissions" />
                                  }
                                },
                                {
                                  path: 'charts',
                                  element: <UnitCharts />,
                                  handle: {
                                    crumb: () => <FormattedMessage id="charts" />
                                  }
                                },
                                {
                                  path: 'overview',
                                  element: <UnitOverview />,
                                  handle: {
                                    crumb: () => <FormattedMessage id="overview" />
                                  }
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default Routes;
