import { Column, SortingRule } from 'react-table';

// types
import { Static } from 'types/static';
import { PaginatedFilter } from 'types/pagination';
import { ENUM_FILTER_OPERATOR_TYPE, FilterExp } from 'types/key-set-pagination';
import { TimeIntervalEnum } from 'types/time-interval.enum';

// constants
import { DAY_MILLISECONDS, CHART_COLORS_3, CHART_COLORS_1, CHART_COLORS_2 } from 'constant';

// fixed data
import { errorMessages } from 'data/error-messages';

// custom axios
import axios from './my-axios';

// utils
import { remove, get, startCase, toLower } from 'lodash';

// config
import { defaultRoleBasedPath } from 'config';

// mui tools
import { hslToRgb } from '@mui/system';

// third-parties
import * as Yup from 'yup';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekYear from 'dayjs/plugin/weekYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(advancedFormat);

/**
 * This function are using to prepare query string for react-table
 * @param sortParam  The array from query string to use in react-table
 * @returns  An array of SortingRule<any>
 */
const prepareSortForTable = (sortParam: string[]) => {
  return sortParam.map((item) => ({ id: item.split(':')[0], desc: item.split(':')[1] === 'DESC' }));
};

/**
 * This function converts sort expression that comes from query-table
 * @param sortParam An array that comes from react-table
 * @returns An array of strings to use in setting query string and calling API
 */
const prepareSortForQuery = (sortParam: SortingRule<any>[]) => {
  return sortParam?.map((item) => `${item.id}:${item.desc ? 'DESC' : 'ASC'}`);
};

/**
 * This function converts sort expression that comes from query-table
 * @param sortParam An object or string that comes from query or react-table
 * @returns An object to use in calling API
 */
function prepareSortForKeySetQuery(sortParam: SortingRule<any>[] | string[]) {
  if (isArrayOfStrings(sortParam)) {
    return sortParam.map((item) => ({ field: item.split(':')[0], order: item.split(':')[1] === 'DESC' ? -1 : 1 }));
  } else {
    return sortParam.map((item) => ({ field: item.id, order: item.desc ? -1 : 1 }));
  }
}

/**
 * This function converts statics into arrays
 * @param statics An object comes from api
 * @returns An object containing Static subjects and their values
 */
function convertStaticsToArray(statics: Static) {
  return Object.fromEntries(
    Object.entries(statics).map(([k, v]) => [k, Object.entries(v).map(([_k, _v]) => ({ id: Number(_k), name: _v }))])
  ) as Record<keyof Static, { id: number; name: string }[]>;
}

/**
 * This function returns the start time of a day
 * @param date
 * @returns date
 */
function getStartDate(date: Date) {
  date.setHours(0, 0, 0, 0);
  return date;
}

/**
 * This function returns the end time of a day
 * @param date
 * @returns date
 */
function getEndDate(date: Date) {
  const ms = date.setHours(0, 0, 0, 0) + DAY_MILLISECONDS - 1;
  return new Date(ms);
}

/**
 * This function returns the end time of a day
 * @param min the min
 * @param max the max
 * @returns a random number between min and max
 */
const randomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * A function to convert HEX value to RGB
 * @param hex a hex value that will converted to rgb
 * @returns on aobject including R G B or null
 */
function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

function getSeparateRgb(rgb: string) {
  var result = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/i.exec(rgb);
  return result
    ? {
        r: parseInt(result[1]),
        g: parseInt(result[2]),
        b: parseInt(result[3])
      }
    : null;
}

function getHslColor(colorNum: number) {
  const hue = colorNum * 137.508;
  return `hsl(${hue},50%,75%)`;
}

/**
 * This function returns colors for charts
 * @param count the number of required colors
 * @param opacity the number of opacity for each color
 * @returns an array of arrays including different opacity colors
 */
const randomColors = (count: number, opacity: number[]) => {
  const colors: string[][] = [];
  for (let i = 0; i < opacity.length; i++) {
    colors.push([]);
  }

  if (count <= CHART_COLORS_3.length) {
    for (let i = 0; i < count; i++) {
      const colorsArray =
        count <= CHART_COLORS_1.length ? CHART_COLORS_1 : count <= CHART_COLORS_2.length ? CHART_COLORS_2 : CHART_COLORS_3;
      const { r, g, b } = hexToRgb(colorsArray[i]) || {};
      for (let j = 0; j < opacity.length; j++) {
        colors[j].push(`rgba(${r},${g},${b},${opacity[j]})`);
      }
    }
  } else {
    for (let i = 0; i < count; i++) {
      const color = getHslColor(i);
      const rgb = hslToRgb(color);
      const { r, g, b } = getSeparateRgb(rgb) || {};
      for (let j = 0; j < opacity.length; j++) {
        colors[j].push(`rgba(${r},${g},${b},${opacity[j]})`);
      }
    }
  }

  return colors;
};

function subtractDays(numOfDays: number, date = new Date()) {
  const dateCopy = new Date(date.getTime());

  dateCopy.setDate(dateCopy.getDate() - numOfDays);

  return dateCopy;
}

/**
 * Returns a proper error message according to the error
 * @param error The error returned from api
 * @returns An proper error message
 */
function getErrorMessage(error: any): string {
  const defaultMessage = 'An error occurred!!!';
  const errorMessage = errorMessages.find((x) => x.errorCode === error?.data?.errorCode);
  return errorMessage?.message ?? error?.data?.message ?? defaultMessage;
}

/**
 * This function prepare the date for send to Mysql on BE
 * @param date The date to change to mysql date format
 * @param type Specifies whether it is needed to be the start, end or the same time in the date
 * @param withoutTimeZone To include time zone in calculation or not
 * @returns A string format of the date prepared to send to Back-end
 */
function getMysqlDate(date: string | Date, type: 'default' | 'start' | 'end' = 'default', withoutTimeZone: boolean = true): string | null {
  try {
    let _date: Date = new Date(date);

    if (withoutTimeZone) {
      const userTimezoneOffset = _date.getTimezoneOffset() * 60000;
      _date = new Date(_date.getTime() - userTimezoneOffset);
    }

    if (type === 'start') {
      return _date.toISOString().slice(0, 10);
    } else if (type === 'end') {
      return _date.toISOString().slice(0, 10) + ' 23:59:59';
    } else {
      return _date.toISOString().slice(0, 19).replace('T', ' ');
    }
  } catch (error: any) {
    return null;
  }
}

/**
 * Returns true if string is a number otherwise false
 * @param str A string
 * @returns True if string is a number exactly otherwise false
 */
function isStringRepresentsNumber(str: string) {
  return str ? /^\d+$/.test(str) : false;
}

/**
 * Convert the date to a custom format(without tz)
 * @param date A date, string(includes a valid date), number(timestamp)
 * @returns ISO date string (YYYY/MM/DD HH:MM:SS)
 */
function getISOString(date: Date | string | number): string | null {
  try {
    return new Date(date).toISOString().slice(0, 16).replace('T', ' ').replaceAll('-', '/');
  } catch (error: any) {
    return null;
  }
}

async function downloadFile(url: string, filename: string, params?: any) {
  await axios({
    url,
    method: 'GET',
    responseType: 'blob',
    params
  }).then((response) => {
    const contentDispositionHeader = response.headers['content-disposition'];

    const filenameFromHeader = contentDispositionHeader ? getFilenameFromContentDisposition(contentDispositionHeader) : undefined;

    // Use the filename from the response header if available, otherwise use the provided filename parameter
    const finalFilename = filenameFromHeader || filename;

    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', finalFilename); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
}

/**
 * Remove columns according to conditions
 * @param columns Table columns
 * @param removeColumns Columns to be removed
 */
function removeTableColumns<T extends {}>(columns: Column<T>[], removeColumns: { accessor?: keyof T; id?: string; condition: boolean }[]) {
  const columnsToBeRemoved = removeColumns.filter((y) => y.condition).map(({ accessor, id }) => ({ accessor, id }));
  remove(columns, (x) => columnsToBeRemoved.some((y) => y.accessor === x.accessor && y.id === x.id));
}

function getBaseUrl() {
  //TODO temp solution !
  let baseUrl = 'http://localhost:8080/api'; // local
  const hostname = window.location.hostname;
  if (hostname.toLowerCase().includes('flowdx')) {
    // dev
    baseUrl = 'https://v2.flowdx.xyz/api';
  } else if (hostname.toLowerCase().includes('drizzlex')) {
    // prod
    baseUrl = 'https://v2.drizzlex.com/api';
  }

  return baseUrl;
}

/**
 * Returns the default path(route) based on user role from config
 * @param userRoleTypeId User role type id (Number)
 * @returns default path (String)
 */
function getDefaultPath(userRoleTypeId: number): string {
  return defaultRoleBasedPath.find((x) => x.roles.includes(userRoleTypeId))?.path || '';
}

/**
 * This function is case insensitive version of lodash get
 * @param object The object that will be searched
 * @param path The path of property
 * @param defaultValue If property not found this value will return
 * @returns the value of property in the object
 */
function geti(object: any, path: string, defaultValue?: any) {
  const newObj = Object.fromEntries(Object.entries(object).map(([k, v]) => [k.toLowerCase(), v]));
  return get(newObj, path.toLowerCase(), defaultValue);
}

/**
 * This function converts a valid time (date, string, number) to string date (UTC/locale) according to the client pc date format
 * @param value Value is a valid date input
 * @param locale Default is true. Set to false if you want UTC time but in client pc date format
 * @returns Returns a string represents the date in user pc format (UTC/locale)
 */
function getLocaleDateWithoutSeconds(value: Date | string | number, locale: boolean | string = true, onlyDate: boolean = false) {
  let date = value instanceof Date ? value : new Date(value);

  let options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };

  if (!onlyDate) {
    options = {
      ...options,
      hour: '2-digit',
      minute: '2-digit'
    };
  }

  // Check if locale is a string and update the timeZone option accordingly
  if (typeof locale === 'string') {
    options.timeZone = locale.trim() || undefined;
  } else if (!locale) {
    date = new Date(date.toISOString().slice(0, -1));
  }

  return new Intl.DateTimeFormat(undefined, options).format(date);
}

function isArrayOfStrings(value: unknown): value is string[] {
  return Array.isArray(value) && value.every((item) => typeof item === 'string');
}

function getFilenameFromContentDisposition(contentDisposition: string): string | undefined {
  const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
  if (match && match[1]) {
    const filename = match[1].replace(/['"]/g, '');
    return decodeURIComponent(filename);
  }
  return undefined;
}

function getDateRanges(startDate: Date, endDate: Date, timeInterval: TimeIntervalEnum) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const ranges = [];

  let current = new Date(start);

  while (current <= end) {
    let rangeStart, rangeEnd;

    switch (timeInterval) {
      case TimeIntervalEnum.MONTHLY:
        rangeStart = new Date(current);
        rangeEnd = new Date(current.getFullYear(), current.getMonth() + 1, 0, 23, 59, 59, 999);
        ranges.push({ start: rangeStart, end: rangeEnd > end ? end : rangeEnd });
        current.setMonth(current.getMonth() + 1);
        current.setDate(1);
        break;

      case TimeIntervalEnum.WEEKLY:
        rangeStart = new Date(current);
        // Calculate the end of the week (Saturday)
        rangeEnd = new Date(current);
        rangeEnd.setDate(rangeEnd.getDate() + (6 - rangeEnd.getDay())); // Move to Saturday
        rangeEnd.setHours(23, 59, 59, 999);

        ranges.push({
          start: rangeStart,
          end: rangeEnd > end ? end : rangeEnd
        });

        // Move current to the next Sunday
        current.setDate(current.getDate() + (7 - current.getDay())); // Move to next Sunday
        break;

      case TimeIntervalEnum.DAILY:
        rangeStart = new Date(current);
        rangeEnd = new Date(current);
        rangeEnd.setHours(23, 59, 59, 999);
        ranges.push({ start: rangeStart, end: rangeEnd > end ? end : rangeEnd });
        current.setDate(current.getDate() + 1);
        break;

      case TimeIntervalEnum.HOURLY:
        rangeStart = new Date(current);
        rangeEnd = new Date(current);
        rangeEnd.setMinutes(59, 59, 999);
        ranges.push({ start: rangeStart, end: rangeEnd > end ? end : rangeEnd });
        current.setHours(current.getHours() + 1);
        current.setMinutes(0, 0, 0);
        break;

      default:
        throw new Error('Invalid timeInterval');
    }
  }

  return ranges;
}

function getClientTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * To get client timezone offset in the format of +00:00
 * @returns client timezone offset in the format of +00:00
 */
function getClientTimeZoneOffset() {
  const now = new Date();
  const offsetMinutes = now.getTimezoneOffset();
  const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
  const offsetMinutesRemainder = Math.abs(offsetMinutes % 60);
  const offsetSign = offsetMinutes < 0 ? '+' : '-';

  return `${offsetSign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutesRemainder).padStart(2, '0')}`;
}

const yupObjectValidate = () => Yup.object().transform((value) => value ?? undefined);

function getQueryParamArrayValue(paramObj: Record<string, string | undefined>, key: string) {
  const stateParam = paramObj[key] ? paramObj[key]?.split(':')[1] : '';
  return stateParam ? stateParam.split(',').map((item) => Number(item)) : undefined;
}

function toTitleCase(str: string) {
  return startCase(toLower(str));
}

function generateDateFilterQueryString(startDate?: string | null, endDate?: string | null) {
  if (startDate && endDate) {
    return `$btw:${startDate},${endDate}`;
  } else if (startDate) {
    return `$gte:${startDate}`;
  } else if (endDate) {
    return `$lte:${endDate}`;
  } else {
    return '';
  }
}

function extractDatesFromQueryString(param: string) {
  let startDate: Date | null = null;
  let endDate: Date | null = null;

  if (param) {
    if (param.includes('btw')) {
      const dates = param.split('btw:')[1].split(',');
      startDate = new Date(dates[0]);
      endDate = new Date(dates[1]);
    } else if (param.includes('gte')) {
      startDate = new Date(param.split('gte:')[1]);
    } else if (param.includes('lte')) {
      endDate = new Date(param.split('lte:')[1]);
    }
  }

  return { startDate, endDate };
}

export function parseFilter(filter: PaginatedFilter | undefined, dateFields: string[], timeZone: string) {
  if (!filter) return undefined;

  let _filter = { ...filter };
  for (const field of dateFields) {
    const prop = _filter[`filter.${field}`];
    const value = parseFilterDate(prop as string, timeZone);
    if (value) {
      _filter = { ..._filter, [`filter.${field}`]: value };
    }
  }
  return _filter;
}

function parseFilterDate(exp?: string, timeZone?: string) {
  const dateRangeMatch = exp?.match(/^\$btw:(.+),(.+)$/);
  if (dateRangeMatch) {
    let startDate = dateRangeMatch[1];
    let endDate = dateRangeMatch[2];

    if (timeZone) {
      startDate = dayjs(startDate).tz(timeZone, true).toISOString();
      endDate = dayjs(endDate).tz(timeZone, true).toISOString();
    }

    return `$btw:${startDate},${endDate}`;
  }

  const operatorMatch = exp?.match(/^\$(\w+):(.+)$/);
  if (operatorMatch) {
    const operator = operatorMatch[1];
    let operand = operatorMatch[2];

    if (timeZone) {
      operand = dayjs(operand).tz(timeZone, true).toISOString();
    }

    return `$${operator}:${operand}`;
  }
}

export function addDateFilterToExpression(
  filter: FilterExp[],
  startDate: Date | undefined,
  endDate: Date | undefined,
  timeZone?: string | null,
  notificationFilter?: boolean
) {
  let dateFrom = undefined;
  let dateTo = undefined;

  if (startDate) {
    if (timeZone) {
      dateFrom = dayjs(getStartDate(startDate)).tz(timeZone, true).valueOf();
    } else {
      dateFrom = getStartDate(startDate).getTime();
    }
  }

  if (endDate) {
    if (timeZone) {
      dateTo = dayjs(getEndDate(endDate)).tz(timeZone, true).valueOf();
    } else {
      dateTo = getEndDate(endDate).getTime();
    }
  }

  if (!notificationFilter) {
    if (dateFrom && dateTo) {
      filter?.push({
        name: 'timestamp',
        operator: ENUM_FILTER_OPERATOR_TYPE.between,
        arr_value: [dateFrom, dateTo]
      });
    } else if (dateFrom) {
      filter?.push({ name: 'timestamp', operator: ENUM_FILTER_OPERATOR_TYPE.gte, value: dateFrom });
    } else if (dateTo) {
      filter?.push({ name: 'timestamp', operator: ENUM_FILTER_OPERATOR_TYPE.lt, value: dateTo });
    }
  } else {
    if (dateFrom && dateTo) {
      filter?.push({ name: 'timestamp', operator: ENUM_FILTER_OPERATOR_TYPE.gte, value: dateFrom });
      filter?.push({ name: 'update_time', operator: ENUM_FILTER_OPERATOR_TYPE.lt, value: dateTo });
    } else if (dateFrom) {
      filter?.push({ name: 'timestamp', operator: ENUM_FILTER_OPERATOR_TYPE.gte, value: dateFrom });
    } else if (dateTo) {
      filter?.push({ name: 'update_time', operator: ENUM_FILTER_OPERATOR_TYPE.lt, value: dateTo });
    }
  }
}

function serializeObjectToQueryString(obj: Record<string, string | number | boolean>) {
  return Object.keys(obj)
    .map((key) => {
      return `${key}=${encodeURIComponent(obj[key])}`;
    })
    .join('&');
}

const addFilterToParamObj = (
  paramObj: { [key: string]: string },
  filters: { key: string; value?: string | number | (string | number)[] | null; operator: string }[]
) => {
  filters.forEach(({ key, value, operator }) => {
    let paramValue: string = '';

    if (Array.isArray(value)) {
      paramValue = value.length ? `$${operator}:${value.join(',')}` : '';
    } else {
      paramValue = value ? `$${operator}:${value}` : '';
    }

    paramObj[`filter.${key}`] = paramValue;
  });
};

const extractFilterValues = (
  paramObj: Record<string, string> | undefined,
  keys: { key: string; op: string }[]
): { [key: string]: string | number | (string | number)[] | undefined | null } => {
  const filterValues: { [key: string]: string | string[] | undefined } = {};

  keys.forEach(({ key, op }) => {
    const paramValue = paramObj?.[key];

    if (paramValue) {
      const value = paramValue.split(`${op}:`)[1];
      filterValues[key] = op === 'in' ? value.split(',') : value;
    }
  });

  return filterValues;
};

const getEnumValues = (value: any) => {
  return Object.values(value);
};

function getAdjustedStartDate(timeInterval: TimeIntervalEnum, currentDate: Date): Date {
  const startDate = new Date(currentDate);

  switch (timeInterval) {
    case TimeIntervalEnum.MONTHLY:
      startDate.setMonth(startDate.getMonth() - 12);
      startDate.setDate(1);
      startDate.setHours(0, 0, 0, 0);
      break;

    case TimeIntervalEnum.WEEKLY:
      // Set the date to the previous Sunday
      const day = startDate.getDay();
      const diffToLastSunday = day === 0 ? 7 : day; // If it's Sunday (day 0), we need to go back 7 days
      startDate.setDate(startDate.getDate() - diffToLastSunday);
      startDate.setDate(startDate.getDate() - 7 * 12); // 12 weeks ago
      startDate.setHours(0, 0, 0, 0);
      break;

    case TimeIntervalEnum.DAILY:
      startDate.setDate(startDate.getDate() - 12); // 12 days ago
      startDate.setHours(0, 0, 0, 0);
      break;

    case TimeIntervalEnum.HOURLY:
      startDate.setHours(startDate.getHours() - 24); // 24 hours ago
      startDate.setMinutes(0, 0, 0);
      break;

    default:
      throw new Error('Invalid timeInterval');
  }

  return startDate;
}

function formatDate(date: Date, format: string) {
  return dayjs(date).format(format);
}

const wait = (seconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

function setTimeZone(date: Date, timeZone: string) {
  return dayjs(date).tz(timeZone, true).toDate();
}

async function uploadFile(file: File, uploadPath: string) {
  const formData = new FormData();
  formData.append('file', file);

  return await axios.post(uploadPath, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export {
  prepareSortForTable,
  prepareSortForQuery,
  prepareSortForKeySetQuery,
  convertStaticsToArray,
  getStartDate,
  getEndDate,
  randomColors,
  subtractDays,
  randomInt,
  getErrorMessage,
  getMysqlDate,
  isStringRepresentsNumber,
  getISOString,
  downloadFile,
  removeTableColumns,
  getBaseUrl,
  getDefaultPath,
  geti,
  getLocaleDateWithoutSeconds,
  getClientTimezone,
  yupObjectValidate,
  getQueryParamArrayValue,
  getClientTimeZoneOffset,
  toTitleCase,
  generateDateFilterQueryString,
  extractDatesFromQueryString,
  serializeObjectToQueryString,
  addFilterToParamObj,
  extractFilterValues,
  getEnumValues,
  getDateRanges,
  getAdjustedStartDate,
  formatDate,
  wait,
  setTimeZone,
  uploadFile
};
