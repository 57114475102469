export enum TimeIntervalEnum {
  MONTHLY = 1,
  WEEKLY = 2,
  DAILY = 3,
  HOURLY = 4
}

export const DayjsTimeIntervalFormat: Record<TimeIntervalEnum, string> = {
  [TimeIntervalEnum.MONTHLY]: 'YYYY-MM',
  [TimeIntervalEnum.WEEKLY]: 'gggg-ww',
  [TimeIntervalEnum.DAILY]: 'YYYY-MM-DD',
  [TimeIntervalEnum.HOURLY]: 'YYYY-MM-DD HH:00'
};
