import { serverApi } from '../index';

// types
import { Company, CompanyBuildingsCount, CompanyPropertiesCount, CompanyUnitsCount, Threshold } from 'types/company';
import { PaginatedReq, PaginatedRes } from 'types/pagination';
import { Sensor, SensorByEntityFilter } from 'types/sensor';
import { KeySetPaginationReq, KeySetPaginationRes } from 'types/key-set-pagination';
import { Parsed } from 'types/submission';
import { Notification } from 'types/notification';
import { Property } from 'types/property';
import { TimeIntervalEnum } from 'types/time-interval.enum';

// tools
import { isNil } from 'lodash';

export const companyApi = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<PaginatedRes<Company>, PaginatedReq | void>({
      query: (arg) => {
        const { filter, ...others } = arg || {};
        return {
          url: `/companies`,
          params: {
            ...others,
            ...filter
          }
        };
      },
      providesTags: (result) => (result ? [...result.data.map(({ id }) => ({ type: 'Company' as const, id })), 'Company'] : ['Company'])
    }),
    getTopCompanies: builder.query<Company[], string>({
      query: (name) => ({ url: `/companies/search-top/${encodeURIComponent(name)}/5` }),
      providesTags: (result) => (result ? [...result.map(({ id }) => ({ type: 'Company' as const, id })), 'Company'] : ['Company'])
    }),
    getCompaniesName: builder.query<Company[], { excluded?: boolean } | void>({
      query: ({ excluded } = {}) => {
        const queryString = isNil(excluded) ? '' : `?excluded=${excluded ? 1 : 0}`;
        return `/companies/names${queryString}`;
      },
      providesTags: (result) => (result ? [...result.map(({ id }) => ({ type: 'Company' as const, id })), 'Company'] : ['Company'])
    }),
    getCompany: builder.query<Company, number>({
      query: (id) => {
        return `/companies/${id}`;
      },
      providesTags: (result, error, id) => [{ type: 'Company', id }]
    }),
    getCompanyPorpertiesCount: builder.query<CompanyPropertiesCount, number>({
      query: (id) => `/companies/${id}/properties-count`
    }),
    removeCompany: builder.mutation<void, number>({
      query: (id) => ({ url: `/companies/${id}`, method: 'delete' })
    }),
    createCompany: builder.mutation<void, Omit<Company, 'id' | 'created' | 'modified' | 'image_url'>>({
      query: (body) => ({ url: `/companies`, method: 'post', body }),
      invalidatesTags: ['Company']
    }),
    updateCompany: builder.mutation<void, Omit<Company, 'created' | 'modified' | 'image_url'>>({
      query: (data) => {
        const { id, ...body } = data;
        return { url: `/companies/${id}`, method: 'put', body };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Company', id }]
    }),
    getCompanyStatistics: builder.query<Record<string, any>, number>({ query: (id) => `/companies/${id}/statistics` }),
    getSensorsByCompany: builder.query<PaginatedRes<Sensor>, SensorByEntityFilter & PaginatedReq>({
      query: ({ id, ...arg }) => {
        const { filter, ...others } = arg;
        return {
          url: `/companies/${id}/sensors`,
          params: {
            ...others,
            ...filter
          }
        };
      },
      providesTags: (result) => (result ? [...result.data.map(({ id }) => ({ type: 'Sensor' as const, id })), 'Sensor'] : ['Sensor'])
    }),
    getSubmissionsByCompany: builder.query<KeySetPaginationRes<Parsed>, { id: number } & KeySetPaginationReq>({
      query: ({ id, ...arg }) => {
        return { url: `/companies/${id}/submissions`, params: arg || undefined };
      },
      providesTags: (result) =>
        result ? [...result.docs.map(({ _id }) => ({ type: 'ParsedSubmission' as const, _id })), 'ParsedSubmission'] : ['ParsedSubmission']
    }),
    getSubmissionsByCompanyCount: builder.query<{ total_count: number }, { filter?: KeySetPaginationReq['filter']; id: number }>({
      query: ({ id, ...arg }) => {
        return { url: `/companies/${id}/submissions/count`, params: arg || undefined };
      }
    }),
    getAlertsByCompany: builder.query<KeySetPaginationRes<Notification>, { id: number } & KeySetPaginationReq>({
      query: ({ id, ...arg }) => {
        return { url: `/companies/${id}/alerts`, params: arg || undefined };
      },
      providesTags: (result) =>
        result ? [...result.docs.map(({ _id }) => ({ type: 'Notification' as const, _id })), 'Notification'] : ['Notification']
    }),
    getAlertsByCompanyCount: builder.query<{ total_count: number }, { id: number; filter: KeySetPaginationReq['filter'] }>({
      query: ({ id, ...arg }) => {
        return { url: `/companies/${id}/alerts/count`, params: arg || undefined };
      },
      providesTags: ['Notification']
    }),
    getCompanyOpenAlertsCount: builder.query<{ company_id: number; open_alerts_count: number }, number>({
      query: (id) => `/companies/${id}/open-alerts-count`,
      providesTags: ['Notification']
    }),
    getCompanyGallonsByAppliance: builder.query<{ appliance: string; gallons: number }[], { id: number; from: number; to: number }>({
      query: ({ id, from, to }) => `/companies/${id}/appliance-gallons?from=${from}&to=${to}`
    }),
    getCompanyGallons: builder.query<
      { date: string; items: [{ name: string; gallons: number }] }[],
      { id: number; from: number; to: number; ptpd: number; timeInterval: TimeIntervalEnum; timeZone: string }
    >({
      query: ({ id, ...args }) => ({ url: `/companies/${id}/gallons`, params: args })
    }),
    getCompanyAppliancesThresholds: builder.query<PaginatedRes<Threshold>, { id: number } & PaginatedReq>({
      query: ({ id, ...arg }) => ({
        url: `/companies/${id}/appliances-thresholds`,
        params: arg || undefined
      }),
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }) => ({ type: 'CompanyApplianceThreshold' as const, id })), 'CompanyApplianceThreshold']
          : ['CompanyApplianceThreshold']
    }),
    updateCompanyApplianceThreshold: builder.mutation<void, Pick<Threshold, 'company_id' | 'appliance_type_id' | 'threshold' | 'id'>>({
      query: (data) => {
        const { company_id, appliance_type_id, threshold } = data;
        return { url: `/companies/${company_id}/appliances-thresholds/${appliance_type_id}/${threshold}`, method: 'put' };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'CompanyApplianceThreshold', id }]
    }),
    getCompanyPropertiesNames: builder.query<Property[], number>({
      query: (id) => `/companies/${id}/properties-names`,
      providesTags: (result) => (result ? [...result.map(({ id }) => ({ type: 'Property' as const, id })), 'Property'] : ['Property'])
    }),
    getCompanyProperties: builder.query<PaginatedRes<Property>, { id: number } & PaginatedReq>({
      query: ({ id, ...arg }) => ({ url: `/companies/${id}/properties`, params: arg }),
      providesTags: (result) => (result ? [...result.data.map(({ id }) => ({ type: 'Property' as const, id })), 'Property'] : ['Property'])
    }),
    getSubmissionsGallonsCountByCompany: builder.query<{ gallons_count: number }, { id: number } & KeySetPaginationReq>({
      query: ({ id, ...arg }) => {
        return { url: `/companies/${id}/submissions-gallons-count`, params: arg || undefined };
      },
      providesTags: ['ParsedSubmission']
    }),
    getCompanyBuildingsCount: builder.query<CompanyBuildingsCount, number>({
      query: (id) => `/companies/${id}/buildings-count`
    }),
    getCompanyUnitsCount: builder.query<CompanyUnitsCount, number>({
      query: (id) => `/companies/${id}/units-count`
    }),
    getCompanyResidentialUnitsCount: builder.query<number, number>({
      query: (id) => `/companies/${id}/residential-units`
    })
  }),
  overrideExisting: false
});

export const {
  useGetCompaniesQuery,
  useGetCompanyPorpertiesCountQuery,
  useRemoveCompanyMutation,
  useCreateCompanyMutation,
  useLazyGetCompanyQuery,
  useUpdateCompanyMutation,
  useGetCompaniesNameQuery,
  useGetCompanyQuery,
  useGetCompanyStatisticsQuery,
  useGetSensorsByCompanyQuery,
  useGetSubmissionsByCompanyQuery,
  useGetCompanyOpenAlertsCountQuery,
  useGetAlertsByCompanyQuery,
  useGetCompanyGallonsByApplianceQuery,
  useGetCompanyAppliancesThresholdsQuery,
  useUpdateCompanyApplianceThresholdMutation,
  useLazyGetCompanyPropertiesNamesQuery,
  useGetCompanyPropertiesQuery,
  useLazyGetCompanyOpenAlertsCountQuery,
  useLazyGetCompaniesNameQuery,
  useGetSubmissionsGallonsCountByCompanyQuery,
  useGetSubmissionsByCompanyCountQuery,
  useLazyGetTopCompaniesQuery,
  useGetCompanyBuildingsCountQuery,
  useGetCompanyUnitsCountQuery,
  useLazyGetCompanyGallonsQuery,
  useGetCompanyResidentialUnitsCountQuery,
  useGetAlertsByCompanyCountQuery,
  useGetCompanyPropertiesNamesQuery
} = companyApi;
