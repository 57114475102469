import { lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, RouteObject } from 'react-router';

// api's
import { CompanyDetail, getCompanyDetail } from 'utils/raw-api';

// components
import Loadable from 'components/Loadable';

// constants
import { COMPANY_ROLES, SYSTEM_ROLES } from 'constant';

// pages
const CompanyLevel = Loadable(lazy(() => import('pages/companies/company-level')));
const CompanyOverview = Loadable(lazy(() => import('pages/companies/company-overview')));
const CompanyProperties = Loadable(lazy(() => import('pages/companies/company-properties')));
const CompanyAlerts = Loadable(lazy(() => import('pages/companies/company-alerts')));
const CompanyMicroMeters = Loadable(lazy(() => import('pages/companies/company-micro-meters')));
const CompanySubmissions = Loadable(lazy(() => import('pages/companies/company-submissions')));
const CompanyThresholds = Loadable(lazy(() => import('pages/companies/company-thresholds')));
//const CompanyUsers = Loadable(lazy(() => import('pages/companies/company-users')));
const CompanyCharts = Loadable(lazy(() => import('pages/companies/company-charts')));

const CompanyLevelRouts: RouteObject = {
  path: 'companies',
  handle: {
    crumb: () => <FormattedMessage id="companies" />,
    allowedRoles: [...SYSTEM_ROLES, ...COMPANY_ROLES]
  },
  children: [
    {
      path: ':id',
      element: <CompanyLevel />,
      loader: async ({ params }) => {
        if (params.id) {
          return await getCompanyDetail(Number(params.id));
        }
      },
      handle: {
        crumb: ({ company }: CompanyDetail) => <>{company?.name}</>,
        title: ({ company }: CompanyDetail) => <>{company?.name}</>,
        subtitle: ({ company, city, state, country }: CompanyDetail) => {
          const address = [company?.street, city?.name, state?.name, country?.name].filter((x) => x).join(', ');
          return <>{address}</>;
        }
      },
      children: [
        {
          path: '',
          element: <Navigate to="properties" replace />
        },
        {
          path: 'overview',
          element: <CompanyOverview />,
          handle: {
            crumb: () => <FormattedMessage id="overview" />
          }
        },
        {
          path: 'properties',
          element: <CompanyProperties />,
          handle: {
            crumb: () => <FormattedMessage id="properties" />
          }
        },
        {
          path: 'alerts',
          element: <CompanyAlerts />,
          handle: {
            crumb: () => <FormattedMessage id="alerts" />
          }
        },
        {
          path: 'micrometers',
          element: <CompanyMicroMeters />,
          handle: {
            crumb: () => <FormattedMessage id="micrometers" />
          }
        },
        {
          path: 'submissions',
          element: <CompanySubmissions />,
          handle: {
            crumb: () => <FormattedMessage id="submissions" />
          }
        },
        {
          path: 'thresholds',
          element: <CompanyThresholds />,
          handle: {
            crumb: () => <FormattedMessage id="thresholds" />
          }
        },
        /*{
          path: 'users',
          element: <CompanyUsers />,
          handle: {
            crumb: () => <FormattedMessage id="users" />
          }
        },*/
        {
          path: 'charts',
          element: <CompanyCharts />,
          handle: {
            crumb: () => <FormattedMessage id="charts" />
          }
        }
      ]
    }
  ]
};

export default CompanyLevelRouts;
