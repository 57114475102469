import { serverApi } from '../index';

// types
import { Building, BuildingInstaller, BuildingUnitsCount } from 'types/building';
import { PaginatedReq, PaginatedRes } from 'types/pagination';
import { KeySetPaginationReq, KeySetPaginationRes } from 'types/key-set-pagination';
import { Parsed } from 'types/submission';
import { Notification } from 'types/notification';
import { Sensor, SensorByEntityFilter } from 'types/sensor';
import { Unit } from 'types/unit';

//tools
import { getClientTimezone } from 'utils/helper';

export const buildingApi = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    getBuilding: builder.query<Building, number>({
      query: (id) => `/buildings/${id}`,
      providesTags: (_, __, id) => [{ type: 'Building', id }]
    }),
    getBuildingInstallerByBuildingId: builder.query<BuildingInstaller, number>({
      query: (id) => `/buildings/${id}/installer`,
      providesTags: (result) =>
        result ? [{ type: 'BuildingInstaller' as const, id: result.id }, 'BuildingInstaller'] : ['BuildingInstaller']
    }),
    removeBuilding: builder.mutation<void, number>({
      query: (id) => ({ url: `/buildings/${id}`, method: 'DELETE' }),
      invalidatesTags: (_, __, id) => [{ type: 'Building', id }]
    }),
    getBuildingUnitsCount: builder.query<BuildingUnitsCount, number>({
      query: (id) => `/buildings/${id}/units-count`
    }),
    createBuilding: builder.mutation<void, Omit<Building, 'survey_data' | 'created' | 'modified' | 'id'>>({
      query: (body) => ({ url: '/buildings', method: 'POST', body }),
      invalidatesTags: ['Building']
    }),
    createBuildingInstaller: builder.mutation<void, Omit<BuildingInstaller, 'created' | 'modified' | 'id'>>({
      query: ({ building_id, ...body }) => ({ url: `/buildings/${building_id}/installers`, method: 'POST', body }),
      invalidatesTags: ['BuildingInstaller']
    }),
    updateBuilding: builder.mutation<void, Omit<Building, 'survey_data' | 'created' | 'modified' | 'property_id' | 'survey_status'>>({
      query: (data) => {
        const { id, ...body } = data;
        return { url: `/buildings/${id}`, method: 'PUT', body };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'Building', id }]
    }),
    updateBuildingInstaller: builder.mutation<void, Omit<BuildingInstaller, 'created' | 'modified' | 'building_id'>>({
      query: (data) => {
        const { id, ...body } = data;
        return { url: `/buildings/installers/${id}`, method: 'PUT', body };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'BuildingInstaller', id }]
    }),
    updateBuildingSurveyData: builder.mutation<void, { id: number; unit_id: number } & { survey_item: Record<string, string> }>({
      query: (data) => {
        const { id, unit_id, survey_item } = data;
        return { url: `/buildings/${id}/survey`, method: 'PUT', body: { unit_id, ...survey_item } };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'Building', id }]
    }),
    approveBuildingSurvey: builder.mutation<void, number>({
      query: (id) => {
        return { url: `/buildings/${id}/approve-send-survey`, method: 'PUT' };
      },
      invalidatesTags: (_, __, id) => [{ type: 'Building', id }]
    }),
    approveBuildingSurveyByDx: builder.mutation<void, number>({
      query: (id) => {
        return { url: `/buildings/${id}/approve-survey-dx`, method: 'PUT' };
      },
      invalidatesTags: (_, __, id) => [{ type: 'Building', id }]
    }),
    reopenSurvey: builder.mutation<void, number>({
      query: (id) => {
        return { url: `/buildings/${id}/reopen-survey`, method: 'PUT' };
      },
      invalidatesTags: (_, __, id) => [{ type: 'Building', id }]
    }),
    getSubmissionsByBuilding: builder.query<KeySetPaginationRes<Parsed>, { id: number } & KeySetPaginationReq>({
      query: ({ id, ...arg }) => {
        return { url: `/buildings/${id}/submissions`, params: arg || undefined };
      },
      providesTags: (result) =>
        result ? [...result.docs.map(({ _id }) => ({ type: 'ParsedSubmission' as const, _id })), 'ParsedSubmission'] : ['ParsedSubmission']
    }),
    getSubmissionsByBuildingCount: builder.query<{ total_count: number }, { filter?: KeySetPaginationReq['filter']; id: number }>({
      query: ({ id, ...arg }) => {
        return { url: `/buildings/${id}/submissions/count`, params: arg || undefined };
      }
    }),
    getAlertsByBuilding: builder.query<KeySetPaginationRes<Notification>, { id: number } & KeySetPaginationReq>({
      query: ({ id, ...arg }) => {
        return { url: `/buildings/${id}/alerts`, params: arg || undefined };
      },
      providesTags: (result) =>
        result ? [...result.docs.map(({ _id }) => ({ type: 'Notification' as const, _id })), 'Notification'] : ['Notification']
    }),
    getAlertsByBuildingCount: builder.query<{ total_count: number }, { id: number; filter: KeySetPaginationReq['filter'] }>({
      query: ({ id, ...arg }) => {
        return { url: `/buildings/${id}/alerts/count`, params: arg || undefined };
      },
      providesTags: ['Notification']
    }),
    getBuildingOpenAlertsCount: builder.query<{ company_id: number; open_alerts_count: number }, number>({
      query: (id) => `/buildings/${id}/open-alerts-count`,
      providesTags: ['Notification']
    }),
    getBuildingStatistics: builder.query<Record<string, any>, number>({ query: (id) => `/buildings/${id}/statistics` }),
    getSensorsByBuilding: builder.query<PaginatedRes<Sensor>, SensorByEntityFilter & PaginatedReq>({
      query: ({ id, ...arg }) => {
        const { filter, ...others } = arg;
        return {
          url: `/buildings/${id}/sensors`,
          params: {
            ...others,
            ...filter
          }
        };
      },
      providesTags: (result) => (result ? [...result.data.map(({ id }) => ({ type: 'Sensor' as const, id })), 'Sensor'] : ['Sensor'])
    }),
    getBuildingGallonsByAppliance: builder.query<{ appliance: string; gallons: number }[], { id: number; from: number; to: number }>({
      query: ({ id, from, to }) => `/buildings/${id}/appliance-gallons?from=${from}&to=${to}`
    }),
    getBuildingGallonsByUnits: builder.query<{ unit: string; gallons: number }[], { id: number; ptpd: number }>({
      query: ({ id, ...arg }) => ({ url: `/buildings/${id}/units-gallons?timeZone=${getClientTimezone()}`, params: arg })
    }),
    getBuildingGallonsByUnitCategories: builder.query<{ category: string; gallons: number }[], { id: number }>({
      query: ({ id, ...arg }) => ({
        url: `/buildings/${id}/units-gallons/grouped-by-category?timeZone=${getClientTimezone()}`,
        params: arg
      })
    }),
    getBuildingUnits: builder.query<PaginatedRes<Unit>, { id: number } & PaginatedReq>({
      query: ({ id, filter, ...arg }) => ({ url: `/buildings/${id}/units`, params: { ...arg, ...filter } }),
      providesTags: (result) => (result ? [...result.data.map(({ id }) => ({ type: 'Unit' as const, id })), 'Unit'] : ['Unit'])
    }),
    getBuildingTenantsCount: builder.query<{ building_id: number; tenants_count: number }, number>({
      query: (id) => `/buildings/${id}/tenants-count`
    }),
    getBuildingUnitsNames: builder.query<Unit[], number>({
      query: (id) => `/buildings/${id}/units-names`,
      providesTags: (result) => (result ? [...result.map(({ id }) => ({ type: 'Unit' as const, id })), 'Unit'] : ['Unit'])
    }),
    getSubmissionsGallonsCountByBuilding: builder.query<{ gallons_count: number }, { id: number } & KeySetPaginationReq>({
      query: ({ id, ...arg }) => {
        return { url: `/buildings/${id}/submissions-gallons-count`, params: arg || undefined };
      },
      providesTags: ['ParsedSubmission']
    }),
    getTopBuildings: builder.query<Building[], string>({
      query: (name) => ({ url: `/buildings/search-top/${encodeURIComponent(name)}/5` }),
      providesTags: (result) => (result ? [...result.map(({ id }) => ({ type: 'Building' as const, id })), 'Building'] : ['Building'])
    }),
    getBuildingGallonsReport: builder.query<any[], { id: number; from: number; to: number; timeZone?: string }>({
      query: ({ id, ...args }) => ({ url: `/buildings/${id}/gallons/report`, params: args })
    }),
    updateSensorsStatus: builder.mutation<void, { id: number; sensorStatusId: number }>({
      query: ({ id, sensorStatusId }) => {
        return { url: `/buildings/${id}/sensors/status/${sensorStatusId}`, method: 'PUT' };
      },
      invalidatesTags: ['Sensor']
    }),
    updateSensorsState: builder.mutation<void, { id: number; sensorStateId: number }>({
      query: ({ id, sensorStateId }) => {
        return { url: `/buildings/${id}/sensors/state/${sensorStateId}`, method: 'PUT' };
      },
      invalidatesTags: ['Sensor']
    })
  }),

  overrideExisting: false
});

export const {
  useGetBuildingQuery,
  useRemoveBuildingMutation,
  useGetBuildingUnitsCountQuery,
  useLazyGetBuildingQuery,
  useCreateBuildingMutation,
  useUpdateBuildingMutation,
  useGetSubmissionsByBuildingQuery,
  useGetAlertsByBuildingQuery,
  useGetBuildingOpenAlertsCountQuery,
  useGetBuildingStatisticsQuery,
  useGetSensorsByBuildingQuery,
  useGetBuildingGallonsByApplianceQuery,
  useGetBuildingGallonsByUnitsQuery,
  useGetBuildingUnitsQuery,
  useLazyGetBuildingOpenAlertsCountQuery,
  useGetBuildingTenantsCountQuery,
  useLazyGetBuildingUnitsNamesQuery,
  useLazyGetTopBuildingsQuery,
  useLazyGetBuildingGallonsReportQuery,
  useGetAlertsByBuildingCountQuery,
  useApproveBuildingSurveyMutation,
  useUpdateBuildingSurveyDataMutation,
  useGetBuildingUnitsNamesQuery,
  useApproveBuildingSurveyByDxMutation,
  useGetSubmissionsByBuildingCountQuery,
  useReopenSurveyMutation,
  useUpdateSensorsStateMutation,
  useUpdateSensorsStatusMutation,
  useCreateBuildingInstallerMutation,
  useUpdateBuildingInstallerMutation,
  useGetBuildingInstallerByBuildingIdQuery,
  useGetBuildingGallonsByUnitCategoriesQuery
} = buildingApi;
