export type KeySetPaginationRes<T> = {
  docs: T[];
  next_key?: Record<'key' | 'value', any>[];
  total_items: number;
  has_next?: boolean;
};

export type Sort = Record<'field', any> & Record<'order', number>;

export enum ENUM_FILTER_OPERATOR_TYPE {
  /**
   * Equal to
   */
  eq = 'eq',
  /**
   * Greater than
   */
  gt = 'gt',
  /**
   * Greater than or equal to
   */
  gte = 'gte',
  /**
   * Less than
   */
  lt = 'lt',
  /**
   * Less than or equal to
   */
  lte = 'lte',
  /**
   * Regular expression
   */
  regex = 'regex',
  /**
   * Array based search with "$in" mongo operator
   */
  _in = 'in',
  /**
   * Array based search with "$nin" mongo operator
   */
  _nin = 'nin',
  /**
   * Array based search with "$gt and $lt" mongo operator
   */
  between = 'between',
  /**
   * Not equel to
   */
  ne = 'ne'
}

export type FilterExp = {
  name: string | string[];
  operator: ENUM_FILTER_OPERATOR_TYPE;
} & (
  | {
      arr_value?: never;
      value: any;
    }
  | {
      arr_value: (string | number)[];
      value?: never;
    }
);

export type KeySetPaginationReq = {
  sort?: Sort[];
  limit?: number;
  skip?: number;
  start_key?: Record<'key' | 'value', any>[];
  filter?: FilterExp[];
};

export type OtherFilter = Pick<FilterExp, 'name' | 'value' | 'arr_value'> & { operator?: ENUM_FILTER_OPERATOR_TYPE };
