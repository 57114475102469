import { useMemo } from 'react';

// constants
import { COMPANY_ROLES, SYSTEM_ROLES } from 'constant';

// hooks
import useAuth from './useAuth';

const useUserRole = () => {
  const { activeRole } = useAuth();

  return useMemo(
    () => ({
      hasSystemRole: !!activeRole && SYSTEM_ROLES.includes(activeRole.user_role_type_id),
      hasCompanyRole: !!activeRole && COMPANY_ROLES.includes(activeRole.user_role_type_id),
      activeRole
    }),
    [activeRole]
  );
};

export default useUserRole;
