import { createSearchParams } from 'react-router-dom';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  SettingOutlined,
  ExperimentOutlined,
  FileOutlined,
  HddOutlined,
  EyeOutlined,
  ReadOutlined,
  ApiOutlined,
  GroupOutlined,
  AlertOutlined,
  CalendarOutlined
} from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// config
import { DEFAULT_MM_FILTER } from 'config';

// icons
const icons = {
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  SettingOutlined,
  ExperimentOutlined,
  FileOutlined,
  HddOutlined,
  EyeOutlined,
  ReadOutlined,
  ApiOutlined,
  GroupOutlined,
  AlertOutlined,
  CalendarOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboardItems: NavItemType = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'companies',
      title: <FormattedMessage id="companies" />,
      type: 'list',
      url: '/companies',
      icon: icons.GoldOutlined
    },
    {
      id: 'properties',
      title: <FormattedMessage id="properties" />,
      type: 'list',
      url: '/properties',
      icon: icons.GroupOutlined
    },
    {
      id: 'alerts',
      title: <FormattedMessage id="alerts" />,
      type: 'list',
      url: '/alerts',
      icon: icons.AlertOutlined,
      search: createSearchParams({ status: ['1', '2'] }).toString()
    },
    {
      id: 'gateways',
      title: <FormattedMessage id="gateways" />,
      type: 'item',
      url: '/gateways',
      icon: icons.HddOutlined
    },
    {
      id: 'appliances',
      title: <FormattedMessage id="fixtures" />,
      type: 'item',
      url: '/appliances',
      icon: icons.ApiOutlined
    },
    {
      id: 'submissions',
      title: <FormattedMessage id="submissions" />,
      type: 'collapse',
      icon: icons.ReadOutlined,
      children: [
        {
          id: 'submissions-unassigned',
          title: <FormattedMessage id="unassigned" />,
          type: 'item',
          url: '/submissions/unassigned'
        },
        {
          id: 'keep-alive',
          title: <FormattedMessage id="keep-alive" />,
          type: 'item',
          url: '/submissions/keep-alive'
        }
      ]
    },
    {
      id: 'micrometers',
      title: <FormattedMessage id="micrometers" />,
      type: 'collapse',
      icon: icons.EyeOutlined,
      children: [
        {
          id: 'micrometers-assigned',
          title: <FormattedMessage id="assigned" />,
          type: 'item',
          url: '/micrometers/assigned',
          search: createSearchParams({
            ...DEFAULT_MM_FILTER
          }).toString()
        },
        {
          id: 'micrometers-unassigned',
          title: <FormattedMessage id="unassigned" />,
          type: 'item',
          url: '/micrometers/unassigned'
        },
        {
          id: 'micrometers-excluded',
          title: <FormattedMessage id="excluded" />,
          type: 'item',
          url: '/micrometers/excluded'
        },
        {
          id: 'assignment',
          title: <FormattedMessage id="assignment" />,
          type: 'item',
          url: '/micrometers/assignment'
        },
        {
          id: 'config',
          title: <FormattedMessage id="config" />,
          type: 'item',
          url: '/micrometers/config'
        },
        {
          id: 'defaults',
          title: <FormattedMessage id="defaults" />,
          type: 'item',
          url: '/micrometers/defaults'
        },
        {
          id: 'testing',
          title: <FormattedMessage id="testing" />,
          type: 'item',
          url: '/micrometers/testing'
        },
        {
          id: 'downlink',
          title: <FormattedMessage id="downlink" />,
          type: 'item',
          url: '/micrometers/downlink'
        }
      ]
    },
    {
      id: 'users',
      title: <FormattedMessage id="users" />,
      type: 'item',
      url: '/users',
      icon: icons.UserOutlined
    },
    {
      id: 'events',
      title: <FormattedMessage id="events" />,
      type: 'item',
      url: '/events',
      icon: icons.CalendarOutlined
    },
    {
      id: 'overview',
      title: <FormattedMessage id="overview" />,
      type: 'item',
      url: '/overview',
      icon: icons.DashboardOutlined
    }
    /*,
    {
      id: 'reports',
      title: <FormattedMessage id="reports" />,
      type: 'item',
      url: '/reports',
      icon: icons.FileOutlined
    },
    {
      id: 'settings',
      title: <FormattedMessage id="settings" />,
      type: 'item',
      url: '/settings',
      icon: icons.SettingOutlined
    }*/
  ]
};

export default dashboardItems;
