export type Sensor = {
  id: number;
  serial_number: string | null;
  mac_address: string | null;
  sensor_status_id: number;
  sensor_state_id: number;
  property_id: number | null;
  building_id: number | null;
  unit_id: number | null;
  company_id: number | null;
  appliance_type_id: number | null;
  room_type_id: number | null;
  water_type_id: number | null;
  firmware_version: number | null;
  battery: number | null;
  install_date: string | null;
  last_keepalive: string | null;
  last_submission: string | null;
  last_config: string | null;
  config_params: string | null;
  size_inch?: SensorSize;
  config_version?: string | null;
  created: string;
};

export type AssignSensor = {
  mac: string;
  action: AssignSensorAction;
  appliance_type_id: number;
  company_id: number;
  property_id: number;
  building_id: number;
  unit_id: number;
  room_type_id: number;
  water_type_id: number;
  size_inch: SensorSize | null;
};

export const assignSensorActions = ['transfer', 'keep', 'delete'] as const;
export type AssignSensorAction = typeof assignSensorActions[number];

export type SensorConfigDefault = {
  id: number;
  firmware_version: number;
  parameter: string;
  value: number;
};

export type SensorGallonsThreshold = {
  id: number;
  firmware_version: number;
  appliance_type_id: number;
  room_type_id: number;
  threshold: number;
};

export type SensorByEntityFilter = {
  id: number;
  fromDate?: number;
  threshold?: number;
  mac?: string;
  unit_category_id?: number[];
};

export const SENSOR_SIZES = ['3/8', '1/2', '5/8', '3/4', '1', '2'] as const;
export type SensorSize = typeof SENSOR_SIZES[number];

export type SensorDownlinkConfigParams = {
  id: number;
  parameter: string;
  name: string;
  type: string;
  min_range: number | null;
  max_range: number | null;
  description: string | null;
};

export enum SensorVersionsEnum {
  VERSION_1 = '1',
  VERSION_15 = '15',
  VERSION_16 = '16',
  VERSION_20 = '20'
}

export enum SensorStatusesEnum {
  INITIAL = 1,
  TRANSMITTING = 2,
  IDLE = 3,
  DAMAGED = 4,
  DEAD = 5,
  INACCURATE = 6,
  DEAD_EXCLUDED = 7,
  DISCARDED = 8
}

export const NON_TRANSMITTING_SENSOR_STATUSES = [
  SensorStatusesEnum.INITIAL,
  SensorStatusesEnum.IDLE,
  SensorStatusesEnum.DAMAGED,
  SensorStatusesEnum.DEAD,
  SensorStatusesEnum.INACCURATE,
  SensorStatusesEnum.DEAD_EXCLUDED,
  SensorStatusesEnum.DISCARDED
];

export enum SensorStatesEnum {
  VIRTUAL = 1,
  FACTORY = 2,
  ASSEMBLY = 3,
  QA = 4,
  STORAGE = 5,
  SHIPPING = 6,
  INSTALLED = 7,
  REMOVED = 8
}
