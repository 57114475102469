import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// Mui
import { Box, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField, Tooltip, useTheme } from '@mui/material';
import { InputAdornment } from '@mui/material';

// Icons
import { CheckCircleTwoTone, DeleteOutlined, SearchOutlined } from '@ant-design/icons';

// Utils
import { startCase, toLower } from 'lodash';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

// types
import { UserRole, UserRolesEnum } from 'types/auth';
import { Building } from 'types/building';
import { Company } from 'types/company';
import { Property } from 'types/property';
import { Unit } from 'types/unit';

// Components
import IconButton from '../@extended/IconButton';
import ConfirmDialog, { DialogAction } from '../dialogs/ConfirmDialog';
import Spinner from '../Spinner';

// Redux
import { useGetUserRolesQuery, useRemoveUserRoleMutation } from 'store/reducers/api/user';
import { useLazyGetCompanyQuery } from 'store/reducers/api/company';
import { useLazyGetPropertyQuery } from 'store/reducers/api/property';
import { useLazyGetBuildingQuery } from 'store/reducers/api/building';
import { useLazyGetUnitQuery } from 'store/reducers/api/unit';

// Hooks
import useSnackbar from 'hooks/useSnackbar';
import useAuth from 'hooks/useAuth';
import useIsMounted from 'hooks/useIsMounted';

const getRoleType = (id: number): string => UserRolesEnum[id];
const capitalizeEachWord = (str: string) => startCase(toLower(str));

type Role = {
  userRole: UserRole;
  company?: Company;
  property?: Property;
  building?: Building;
  unit?: Unit;
};

export interface UserRolesListProps {
  userId: number;
  showDeleteButton?: boolean;
  showActiveRole?: boolean;
  isItemButton?: boolean;
  onSelectRole?: (userRole: UserRole) => void;
  showSearch?: boolean;
}

function UserRolesList({ userId, showDeleteButton, showActiveRole, isItemButton, onSelectRole, showSearch }: UserRolesListProps) {
  const theme = useTheme();
  const snackbar = useSnackbar();
  const { activeRole } = useAuth();
  const isMounted = useIsMounted();

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');

  /** holding id to remove */
  const removeId = useRef<number>();

  const { data: userRoles } = useGetUserRolesQuery(userId, { refetchOnMountOrArgChange: true });
  const [getCompany] = useLazyGetCompanyQuery();
  const [getProperty] = useLazyGetPropertyQuery();
  const [getBuilding] = useLazyGetBuildingQuery();
  const [getUnit] = useLazyGetUnitQuery();
  const [removeUserRole] = useRemoveUserRoleMutation();

  const setRolesValue = (index: number, item: Partial<Role>) => {
    setRoles((prev) => {
      const _prev = [...prev];
      _prev[index] = { ..._prev[index], ...item };
      return _prev;
    });
  };

  const loadData = useCallback(async () => {
    if (userRoles) {
      const promises = [] as Promise<{ res: any; promise: keyof Role; index: number }>[];
      setRoles([]);
      for (let index = 0; index < userRoles.length; index++) {
        const userRole = userRoles[index];

        setRolesValue(index, { userRole });

        if (userRole.company_id) {
          promises.push(getCompany(userRole.company_id, true).then(({ data }) => ({ res: data, promise: 'company', index })));
        }

        if (userRole.property_id) {
          promises.push(getProperty(userRole.property_id, true).then(({ data }) => ({ res: data, promise: 'property', index })));
        }

        if (userRole.building_id) {
          promises.push(getBuilding(userRole.building_id, true).then(({ data }) => ({ res: data, promise: 'building', index })));
        }

        if (userRole.unit_id) {
          promises.push(getUnit(userRole.unit_id, true).then(({ data }) => ({ res: data, promise: 'unit', index })));
        }
      }

      try {
        setIsLoading(true);

        const results = await Promise.allSettled(promises);

        if (!isMounted()) return;

        results.forEach((result) => {
          if (result.status === 'fulfilled') {
            const { promise, res, index } = result.value;

            setRolesValue(index, { [promise]: res });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        if (isMounted()) setIsLoading(false);
      }
    }
  }, [userRoles, getCompany, getProperty, getBuilding, getUnit, isMounted]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    removeId.current = undefined;
  };

  const handleRemove = async () => {
    setOpenConfirmDialog(false);
    if (!removeId.current) return;

    if (roles.length === 1) {
      snackbar.showError('User must have at least one role');
      return;
    }

    try {
      removeUserRole({ roleId: removeId.current });
      snackbar.showSuccess('Deleted successfully');
    } catch (error) {
      console.error(error);
      snackbar.showError('An error occurred');
    }
  };

  const actions: Record<DialogAction, () => void> = {
    ok: handleRemove,
    cancel: handleCloseConfirmDialog
  };

  const handleDialogAction = (value: DialogAction) => {
    actions[value]?.();
  };

  const handleClickOnRemove = (id: number) => {
    removeId.current = id;
    setOpenConfirmDialog(true);
  };

  const filteredRoles = useMemo(() => {
    const _roles = roles.map(({ userRole, company, property, building, unit }) => {
      const exp = [capitalizeEachWord(getRoleType(userRole.user_role_type_id))];
      if (userRole.user_role_type_id > UserRolesEnum.SYSTEM_SUPPORT && userRole.user_role_type_id !== UserRolesEnum.SENSOR_TESTER) {
        exp.push(' of');
      }
      if (company) {
        exp.push(` ${company.name}`);
      }
      if (property) {
        exp.push(`, ${property.name}`);
      }
      if (building) {
        exp.push(`, ${building.name}`);
      }
      if (unit) {
        exp.push(`, ${unit.name}`);
      }

      return { roleDescription: exp.join(''), userRole };
    });

    return _roles.filter((x) => x.roleDescription.toLowerCase().includes(searchKeyword.toLowerCase()));
  }, [roles, searchKeyword]);

  return (
    <Box sx={{ height: '100%', overflow: 'auto', margin: '0 -20px', padding: '0 20px' }}>
      {isLoading && <Spinner />}
      <ConfirmDialog open={openConfirmDialog} onClose={handleCloseConfirmDialog} onAction={handleDialogAction} />
      {showSearch && (
        <Box
          sx={{
            position: 'sticky',
            top: 1,
            zIndex: 1,
            backgroundColor: '#fff',
            padding: 1,
            borderRadius: 2,
            boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28);'
          }}
        >
          <TextField
            value={searchKeyword}
            onChange={({ currentTarget: { value } }) => setSearchKeyword(value)}
            fullWidth
            sx={{ padding: 0 }}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: -0.5 }}>
                  <IconButton size="small">
                    <SearchOutlined style={{ fontSize: 'inherit' }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            placeholder="Type to search..."
          />
        </Box>
      )}
      <List>
        {filteredRoles.map(({ userRole, roleDescription }, index) => {
          const Wrapper = ({ children }: { children: React.ReactNode }) =>
            isItemButton ? <ListItemButton onClick={() => onSelectRole?.(userRole)}>{children}</ListItemButton> : <>{children}</>;

          const showUserActiveRole = showActiveRole && activeRole && activeRole.id === userRole.id && userId === userRole.user_id;

          const matches = match(roleDescription, searchKeyword, { insideWords: true });
          const parts = parse(roleDescription, matches);

          return (
            <ListItem
              divider={filteredRoles.length > 1}
              key={index}
              disablePadding={isItemButton}
              {...(showUserActiveRole && {
                secondaryAction: (
                  <Tooltip title="Active Role">
                    <CheckCircleTwoTone twoToneColor={theme.palette.success.main} />
                  </Tooltip>
                )
              })}
            >
              <Wrapper>
                {showDeleteButton && (
                  <ListItemAvatar>
                    <IconButton color="error" onClick={() => handleClickOnRemove(userRole.id)}>
                      <DeleteOutlined />
                    </IconButton>
                  </ListItemAvatar>
                )}
                <ListItemText
                  primary={parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                />
              </Wrapper>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

export default UserRolesList;
