// Mui
import { Checkbox, Dialog, DialogProps, Divider, FormControlLabel } from '@mui/material';

// components
import DialogForm from 'components/DialogForm';
import DialogHeader from 'components/DialogHeader';

// hooks
import useSnackbar from 'hooks/useSnackbar';

// third-parties & utils
import { Formik } from 'formik';
import { capitalize } from 'lodash';
import { exportToCSV } from 'utils/export';
import { getClientTimezone } from 'utils/helper';

const EXPORT_ITEMS = [
  { url: 'companies/export-all', item: 'companies' },
  { url: 'properties/export-all', item: 'properties' },
  { url: 'buildings/export-all', item: 'buildings' },
  { url: 'units/export-all', item: 'units' },
  { url: 'sensors/export-all', item: 'microMeters' },
  { url: 'gateways/export-all', item: 'gateways' },
  { url: 'users/export-all', item: 'users' },
  { url: 'location/cities/export-all', item: 'cities' },
  { url: 'notifications/export-all', item: 'alerts' }
];

interface ExportToCsvDialogProps extends Omit<DialogProps, 'onClose'> {
  onClose?: () => void;
}

export default function ExportToCsvDialog({ onClose, ...others }: ExportToCsvDialogProps) {
  const snackbar = useSnackbar();

  const handleClose = () => {
    onClose?.();
  };

  return (
    <Dialog sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: 400, maxHeight: 800 } }} {...others} onClose={handleClose}>
      <DialogHeader onClose={handleClose}>Export to CSV</DialogHeader>
      <Divider />
      <Formik
        initialValues={EXPORT_ITEMS.reduce((acc, curr) => ({ ...acc, [curr.item]: false }), {})}
        enableReinitialize={true}
        onSubmit={async (rawValues) => {
          const selectedItems = EXPORT_ITEMS.filter((item) => rawValues[item.item as keyof typeof rawValues]);

          try {
            const promieses = [];
            const timestamp = new Date().getTime();
            for (const { url, item } of selectedItems) {
              const filename = `${item}-${timestamp}`;
              promieses.push(exportToCSV({ url, filename, params: { timeZone: getClientTimezone() } }));
            }
            await Promise.all(promieses);

            snackbar.showSuccess('All files were downloaded successfully');
            handleClose();
          } catch (error: any) {
            let message = 'An error has occurred';
            if (error.response?.data) {
              const errorObj = JSON.parse(await error.response.data.text());
              message = errorObj.message;
            }

            if (error.data?.message) {
              message = error.data.message;
            }

            snackbar.showError(message);
          }
        }}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
          const isValid = Object.values(values).some((value) => value);
          return (
            <form noValidate onSubmit={handleSubmit}>
              <DialogForm
                onClickCancel={() => handleClose()}
                isSubmitting={isSubmitting}
                okBtnProps={{ disabled: !isValid || isSubmitting }}
                okBtnLabel="Export"
                items={Object.keys(values).map((key) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={key}
                        name={key}
                        checked={values[key as keyof typeof values]}
                        onChange={() => setFieldValue(key, !values[key as keyof typeof values])}
                      />
                    }
                    label={capitalize(key)}
                  />
                ))}
              />
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
}
