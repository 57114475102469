import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';

// utils
import { getDefaultPath } from 'utils/helper';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, activeRole } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (isLoggedIn && activeRole) {
      navigate(
        {
          pathname: searchParams.get('returnUrl') || getDefaultPath(activeRole.user_role_type_id),
          search: searchParams.get('search') || undefined
        },
        { replace: true }
      );
    }
  }, [isLoggedIn, navigate, searchParams, activeRole]);

  return children;
};

export default GuestGuard;
