// Mui
import { ClickAwayListener, ClickAwayListenerProps, Paper, Popper, PopperProps } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// extended
import Transitions from './@extended/Transitions';

const PopperStyled = styled(Popper)(({ theme }) => ({
  overflow: 'visible',
  zIndex: 1202,
  minWidth: 180,
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 38,
    left: -5,
    width: 10,
    height: 10,
    backgroundColor: theme.palette.background.paper,
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 120,
    borderLeft: `1px solid ${theme.palette.grey.A800}`,
    borderBottom: `1px solid ${theme.palette.grey.A800}`
  }
}));

interface SimplePopperProps extends PopperProps {
  onClickAway: ClickAwayListenerProps['onClickAway'];
  children: ClickAwayListenerProps['children'];
}

const SimplePopper = ({ onClickAway, children, ...popperProps }: SimplePopperProps) => {
  const theme = useTheme();
  return (
    <PopperStyled
      placement="right-start"
      style={{
        zIndex: 2001
      }}
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-12, 1]
            }
          }
        ]
      }}
      {...popperProps}
    >
      {({ TransitionProps }) => (
        <Transitions in={popperProps.open} {...TransitionProps}>
          <Paper
            sx={{
              overflow: 'hidden',
              mt: 1.5,
              boxShadow: theme.customShadows.z1,
              backgroundImage: 'none',
              border: `1px solid ${theme.palette.divider}`
            }}
          >
            <ClickAwayListener onClickAway={onClickAway}>{children}</ClickAwayListener>
          </Paper>
        </Transitions>
      )}
    </PopperStyled>
  );
};

export { PopperStyled, SimplePopper };
