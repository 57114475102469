// icons
import { CloseOutlined } from '@ant-design/icons';

// Mui
import { DialogTitle, Grid } from '@mui/material';

// Components
import IconButton from './@extended/IconButton';

interface DialogHeaderProps {
  children?: JSX.Element | string;
  onClose?: () => void;
}

function DialogHeader({ onClose, children }: DialogHeaderProps) {
  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
      sx={{ borderBottom: '1px solid {theme.palette.divider}' }}
      flexWrap={'nowrap'}
    >
      <Grid item>
        <DialogTitle>{children}</DialogTitle>
      </Grid>
      <Grid item sx={{ mr: 1.5 }}>
        <IconButton color="secondary" onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default DialogHeader;
