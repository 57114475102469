import { serverApi } from '../index';

// types
import { PaginatedReq, PaginatedRes } from 'types/pagination';
import { Property, PropertyBuildingsCount, PropertyUnitsCount } from 'types/property';
import { Sensor, SensorByEntityFilter } from 'types/sensor';
import { KeySetPaginationReq, KeySetPaginationRes } from 'types/key-set-pagination';
import { Parsed } from 'types/submission';
import { Notification } from 'types/notification';
import { Building } from 'types/building';
import { TimeIntervalEnum } from 'types/time-interval.enum';

export const propertyApi = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    getProperties: builder.query<PaginatedRes<Property>, PaginatedReq | void>({
      query: (arg) => {
        const { filter, ...others } = arg || {};
        return {
          url: `/properties`,
          params: {
            ...others,
            ...filter
          }
        };
      },
      providesTags: (result) => (result ? [...result.data.map(({ id }) => ({ type: 'Property' as const, id })), 'Property'] : ['Property'])
    }),
    getProperty: builder.query<Property, number>({
      query: (id) => `/properties/${id}`,
      providesTags: (_, __, id) => [{ type: 'Property', id }]
    }),
    getPorpertyBuildingsCount: builder.query<PropertyBuildingsCount, number>({
      query: (id) => `/properties/${id}/buildings-count`
    }),
    removeProperty: builder.mutation<void, number>({
      query: (id) => ({ url: `/properties/${id}`, method: 'DELETE' }),
      invalidatesTags: (_, __, id) => [{ type: 'Property', id }]
    }),
    createProperty: builder.mutation<void, Omit<Property, 'id' | 'created' | 'modified'>>({
      query: (body) => ({ url: '/properties', method: 'POST', body }),
      invalidatesTags: ['Property']
    }),
    updateProperty: builder.mutation<void, Omit<Property, 'created' | 'modified' | 'company_id'>>({
      query: (data) => {
        const { id, ...body } = data;
        return { url: `/properties/${id}`, method: 'PUT', body };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'Property', id }]
    }),
    getSensorsByProperty: builder.query<PaginatedRes<Sensor>, SensorByEntityFilter & PaginatedReq>({
      query: ({ id, ...arg }) => {
        const { filter, ...others } = arg;
        return {
          url: `/properties/${id}/sensors`,
          params: {
            ...others,
            ...filter
          }
        };
      },
      providesTags: (result) => (result ? [...result.data.map(({ id }) => ({ type: 'Sensor' as const, id })), 'Sensor'] : ['Sensor'])
    }),
    getSubmissionsByProperty: builder.query<KeySetPaginationRes<Parsed>, { id: number } & KeySetPaginationReq>({
      query: ({ id, ...arg }) => {
        return { url: `/properties/${id}/submissions`, params: arg || undefined };
      },
      providesTags: (result) =>
        result ? [...result.docs.map(({ _id }) => ({ type: 'ParsedSubmission' as const, _id })), 'ParsedSubmission'] : ['ParsedSubmission']
    }),
    getSubmissionsByPropertyCount: builder.query<{ total_count: number }, { filter?: KeySetPaginationReq['filter']; id: number }>({
      query: ({ id, ...arg }) => {
        return { url: `/properties/${id}/submissions/count`, params: arg || undefined };
      }
    }),
    getAlertsByProperty: builder.query<KeySetPaginationRes<Notification>, { id: number } & KeySetPaginationReq>({
      query: ({ id, ...arg }) => {
        return { url: `/properties/${id}/alerts`, params: arg || undefined };
      },
      providesTags: (result) =>
        result ? [...result.docs.map(({ _id }) => ({ type: 'Notification' as const, _id })), 'Notification'] : ['Notification']
    }),
    getAlertsByPropertyCount: builder.query<{ total_count: number }, { id: number; filter: KeySetPaginationReq['filter'] }>({
      query: ({ id, ...arg }) => {
        return { url: `/properties/${id}/alerts/count`, params: arg || undefined };
      },
      providesTags: ['Notification']
    }),
    getPropertyOpenAlertsCount: builder.query<{ company_id: number; open_alerts_count: number }, number>({
      query: (id) => `/properties/${id}/open-alerts-count`,
      providesTags: ['Notification']
    }),
    getPropertyStatistics: builder.query<Record<string, any>, number>({ query: (id) => `/properties/${id}/statistics` }),
    getPropertyGallonsByAppliance: builder.query<{ appliance: string; gallons: number }[], { id: number; from: number; to: number }>({
      query: ({ id, from, to }) => `/properties/${id}/appliance-gallons?from=${from}&to=${to}`
    }),
    getPropertyGallons: builder.query<
      { date: string; items: [{ name: string; gallons: number }] }[],
      { id: number; from: number; to: number; ptpd: number; timeInterval: TimeIntervalEnum; timeZone: string }
    >({
      query: ({ id, ...args }) => ({ url: `/properties/${id}/gallons`, params: args })
    }),
    getPropertyBuildingsNames: builder.query<Building[], number>({
      query: (id) => `/properties/${id}/buildings-names`,
      providesTags: (result) => (result ? [...result.map(({ id }) => ({ type: 'Building' as const, id })), 'Building'] : ['Building'])
    }),
    getPropertyBuildings: builder.query<PaginatedRes<Building>, { id: number } & PaginatedReq>({
      query: ({ id, ...arg }) => {
        const { filter, ...others } = arg || {};
        return {
          url: `/properties/${id}/buildings`,
          params: {
            ...others,
            ...filter
          }
        };
      },
      providesTags: (result) => (result ? [...result.data.map(({ id }) => ({ type: 'Building' as const, id })), 'Building'] : ['Building'])
    }),
    getSubmissionsGallonsCountByProperty: builder.query<{ gallons_count: number }, { id: number } & KeySetPaginationReq>({
      query: ({ id, ...arg }) => {
        return { url: `/properties/${id}/submissions-gallons-count`, params: arg || undefined };
      },
      providesTags: ['ParsedSubmission']
    }),
    getTopProperties: builder.query<Property[], string>({
      query: (name) => ({ url: `/properties/search-top/${encodeURIComponent(name)}/5` }),
      providesTags: (result) => (result ? [...result.map(({ id }) => ({ type: 'Property' as const, id })), 'Property'] : ['Property'])
    }),
    getPropertyUnitsCount: builder.query<PropertyUnitsCount, number>({
      query: (id) => `/properties/${id}/units-count`
    }),
    getPropertyGallonsReport: builder.query<any[], { id: number; from: number; to: number; timeZone?: string }>({
      query: ({ id, ...args }) => ({ url: `/properties/${id}/gallons/report`, params: args })
    }),
    getPropertyShippingDate: builder.query<string, number>({
      query: (id) => ({ url: `/properties/${id}/shipping-date` })
    })
  }),
  overrideExisting: false
});

export const {
  useGetPropertyQuery,
  useGetPorpertyBuildingsCountQuery,
  useRemovePropertyMutation,
  useCreatePropertyMutation,
  useUpdatePropertyMutation,
  useLazyGetPropertyQuery,
  useGetSensorsByPropertyQuery,
  useGetSubmissionsByPropertyQuery,
  useGetAlertsByPropertyQuery,
  useGetPropertyOpenAlertsCountQuery,
  useGetPropertyStatisticsQuery,
  useGetPropertyGallonsByApplianceQuery,
  useGetPropertyBuildingsQuery,
  useLazyGetPropertyBuildingsNamesQuery,
  useLazyGetPropertyOpenAlertsCountQuery,
  useGetSubmissionsGallonsCountByPropertyQuery,
  useGetSubmissionsByPropertyCountQuery,
  useLazyGetTopPropertiesQuery,
  useGetPropertyUnitsCountQuery,
  useLazyGetPropertyGallonsReportQuery,
  useLazyGetPropertyGallonsQuery,
  useGetAlertsByPropertyCountQuery,
  useGetPropertiesQuery,
  useGetPropertyShippingDateQuery,
  useGetPropertyBuildingsNamesQuery
} = propertyApi;
