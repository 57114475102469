import { downloadFile } from './helper';

const entitiesNames = ['companies', 'properties', 'units', 'buildings'] as const;
type EntityName = typeof entitiesNames[number];

export const exportPrsedSubmissionsToCSV = async (entityName: EntityName, entityId: number, filename: string, params?: any) => {
  const url = `${entityName}/${entityId}/submissions/export`;

  await downloadFile(url, filename, params);
};

export const exportSubmissionsToCSV = async (type: 'keep-alive' | 'unassigned', filename: string, params?: any) => {
  const url = type === 'keep-alive' ? `submissions/keepalive/export` : `submissions/unassigned/export`;

  await downloadFile(url, filename, params);
};

export const exportToCSV = async ({
  entityName,
  entityId,
  type,
  filename,
  params,
  url
}: {
  entityName?: EntityName;
  entityId?: number;
  type?: string;
  filename: string;
  params?: any;
  url?: string;
}) => {
  if (!url && !type) {
    throw new Error('Either provide url or type');
  }

  const _url = url || [entityName, entityId, type, 'export'].filter(Boolean).join('/');

  await downloadFile(_url, filename, params);
};
