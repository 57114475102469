import { useMemo } from 'react';

// material-ui
import { Theme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';

// project import
import useConfig from 'hooks/useConfig';
import Search from './Search';
import Message from './Message';
import Profile from './Profile';
import Localization from './Localization';
import Notification from './Notification';
import Customization from './Customization';
import MobileSection from './MobileSection';
import MegaMenuSection from './MegaMenuSection';

// hooks
import useAuth from 'hooks/useAuth';

// constants
import { COMPANY_ROLES, SYSTEM_ROLES } from 'constant';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { activeRole } = useAuth();
  const { i18n } = useConfig();

  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const localization = useMemo(() => <Localization />, [i18n]);

  const megaMenu = useMemo(() => <MegaMenuSection />, []);

  const hasPermissionForSearch = useMemo(() => {
    if (activeRole) {
      return [...SYSTEM_ROLES, ...COMPANY_ROLES].includes(activeRole.user_role_type_id);
    }
    return false;
  }, [activeRole]);

  return (
    <>
      {!matchesXs && (hasPermissionForSearch ? <Search /> : <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }} />)}
      {!matchesXs && megaMenu}
      {!matchesXs && localization}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

      <Notification />
      <Message />
      <Customization />
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
