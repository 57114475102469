// project import
import { NavItemType } from 'types/menu';
import dashboardItems from './dashboard-items';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [dashboardItems]
};

export default menuItems;
