import { Autocomplete as MuiAutoComplete, AutocompleteProps as MuiAutoCompleteProps, Box } from '@mui/material';

interface AutoCompleteProps<T> {
  getOptionKey?: (option: T) => string | number;
}

function AutoComplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({ getOptionKey, ...others }: MuiAutoCompleteProps<T, Multiple, DisableClearable, FreeSolo> & AutoCompleteProps<T>) {
  return (
    <MuiAutoComplete
      renderOption={(props, option) => (
        <Box component="li" {...props} key={getOptionKey?.(option) || others.getOptionLabel?.(option)}>
          {others.getOptionLabel?.(option)}
        </Box>
      )}
      {...others}
    />
  );
}

export default AutoComplete;
