import { forwardRef, Ref } from 'react';
import { trackClickEvent, TrackedComponentProps } from '../../../utils/analytics';
import { Button, ButtonProps } from '@mui/material';

export interface TrackedButtonProps extends ButtonProps, TrackedComponentProps {}

const TrackedButton = forwardRef(({ trackId, trackData, onClick, ...others }: TrackedButtonProps, ref: Ref<HTMLButtonElement>) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (trackId) {
      trackClickEvent(trackId, trackData);
    }
    if (onClick) {
      onClick(event);
    }
  };

  return <Button ref={ref} onClick={handleClick} {...others} />;
});

TrackedButton.displayName = 'TrackedButton';

export default TrackedButton;
