import { useMemo } from 'react';

// menu fixed items
import menuItem from 'menu-items';

// auth hook
import useUserRole from './useUserRole';

const systemRoleItems = ['overview', 'gateways', 'appliances', 'submissions', 'micrometers', 'properties', 'alerts', 'events'];

const useMenuItems = () => {
  const { hasSystemRole } = useUserRole();
  return useMemo(() => {
    /** if it is not system user these items should be removed */
    const shouldRemove: string[] = hasSystemRole ? [] : systemRoleItems;

    const _menuItems = {
      ...menuItem,
      items: [{ ...menuItem.items[0], children: menuItem.items[0].children?.filter((x) => x.id && !shouldRemove.includes(x.id)) }]
    };

    return _menuItems;
  }, [hasSystemRole]);
};

export default useMenuItems;
