import { Static } from 'types/static';
import { serverApi } from '../index';

const extendedApi = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    getStatic: builder.query<Static, void>({ query: () => '/static' })
  }),
  overrideExisting: false
});

export const { useGetStaticQuery, useLazyGetStaticQuery } = extendedApi;
