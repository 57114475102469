import { serverApi } from '../index';

// types
import {
  AssignSensor,
  Sensor,
  SensorByEntityFilter,
  SensorConfigDefault,
  SensorDownlinkConfigParams,
  SensorGallonsThreshold
} from 'types/sensor';
import { PaginatedReq, PaginatedRes } from 'types/pagination';

const extendedApi = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    removeSensor: builder.mutation<void, number>({
      query: (id) => ({ url: `/sensors/${id}`, method: 'DELETE' }),
      invalidatesTags: (_, __, id) => [{ type: 'Sensor', id }]
    }),
    updateSensorState: builder.mutation<void, { id: number; sensorStateId: number }>({
      query: ({ id, sensorStateId }) => {
        return { url: `/sensors/${id}/state/${sensorStateId}`, method: 'PUT' };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'Sensor', id }]
    }),
    updateSensorStatus: builder.mutation<void, { id: number; sensorStatusId: number }>({
      query: ({ id, sensorStatusId }) => {
        return { url: `/sensors/${id}/status/${sensorStatusId}`, method: 'PUT' };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'Sensor', id }]
    }),
    updateSensorNotificationExcluded: builder.mutation<void, { id: number; status: number }>({
      query: ({ id, status }) => {
        return { url: `/sensors/${id}/notification-excluded/${status}`, method: 'PUT' };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'Sensor', id }]
    }),
    updateSensorNotificationExcludedByMac: builder.mutation<void, { mac: string; status: number }>({
      query: ({ mac, status }) => {
        return { url: `/sensors/notification-excluded/mac/${mac}/${status}`, method: 'PUT' };
      },
      invalidatesTags: ['Sensor']
    }),
    assignSensor: builder.mutation<void, AssignSensor>({
      query: ({ mac, action, ...body }) => {
        return { url: `/sensors/assign?mac=${mac}&action=${action}`, method: 'POST', body };
      }
    }),
    startTesting: builder.mutation<void, void>({
      query: () => ({ url: `/sensors/tested`, method: 'DELETE' })
    }),
    stopTesting: builder.query<string[], void>({
      query: () => `/sensors/tested-failed`
    }),
    getSensorByMac: builder.query<Sensor, string>({
      query: (mac) => `/sensors/mac/${mac}`
    }),
    getTopSensorsByMac: builder.query<Sensor[], string>({
      query: (mac) => `/sensors/search-top/${encodeURIComponent(mac)}/5`
    }),
    getSensor: builder.query<Sensor, number>({
      query: (id) => `/sensors/${id}`
    }),
    updateConfigParams: builder.mutation<void, { id: number; config_params: object }>({
      query: ({ id, config_params }) => {
        return { url: `/sensors/${id}/config`, method: 'PUT', body: config_params };
      }
    }),
    getSensorsConfigDefaults: builder.query<PaginatedRes<SensorConfigDefault>, PaginatedReq>({
      query: (arg) => ({ url: `/sensors/config-defaults`, params: arg }),
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }) => ({ type: 'SensorConfigDefault' as const, id })), 'SensorConfigDefault']
          : ['SensorConfigDefault']
    }),
    getSensorsGallonsThreshold: builder.query<PaginatedRes<SensorGallonsThreshold>, PaginatedReq>({
      query: (arg) => {
        const { filter, ...others } = arg;
        return {
          url: `/sensors/gallon-thresholds`,
          params: {
            ...others,
            ...filter
          }
        };
      },
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }) => ({ type: 'SensorGallonsThreshold' as const, id })), 'SensorGallonsThreshold']
          : ['SensorGallonsThreshold']
    }),
    updateConfigDefaults: builder.mutation<void, { id: number; value: number }>({
      query: ({ id, value }) => {
        return { url: `/sensors/config-defaults/${id}/${value}`, method: 'PUT' };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'SensorConfigDefault', id }]
    }),
    updateSensorGallonsThreshold: builder.mutation<void, { id: number; value: number }>({
      query: ({ id, value }) => {
        return { url: `/sensors/gallon-thresholds/${id}/${value}`, method: 'PUT' };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'SensorGallonsThreshold', id }]
    }),
    getUnassignedSensors: builder.query<PaginatedRes<Sensor>, PaginatedReq & { mac?: string }>({
      query: (arg) => {
        const { filter, ...others } = arg;

        return {
          url: '/sensors/unassigned',
          params: {
            ...others,
            ...filter
          }
        };
      },
      providesTags: (result) => (result ? [...result.data.map(({ id }) => ({ type: 'Sensor' as const, id })), 'Sensor'] : ['Sensor'])
    }),
    getAssignedSensors: builder.query<PaginatedRes<Sensor>, Omit<SensorByEntityFilter, 'id'> & PaginatedReq>({
      query: (arg) => {
        const { filter, ...others } = arg;

        return {
          url: '/sensors/assigned',
          params: {
            ...others,
            ...filter
          }
        };
      },
      providesTags: (result) => (result ? [...result.data.map(({ id }) => ({ type: 'Sensor' as const, id })), 'Sensor'] : ['Sensor'])
    }),
    getSensors: builder.query<PaginatedRes<Sensor>, PaginatedReq>({
      query: (arg) => {
        const { filter, ...others } = arg;

        return {
          url: '/sensors',
          params: {
            ...others,
            ...filter
          }
        };
      },
      providesTags: (result) => (result ? [...result.data.map(({ id }) => ({ type: 'Sensor' as const, id })), 'Sensor'] : ['Sensor'])
    }),
    getDownlinkConfigParams: builder.query<SensorDownlinkConfigParams[], void>({
      query: () => ({ url: `/sensors/downlink-config-params` }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'SensorDownlinkConfigParams' as const, id })), 'SensorDownlinkConfigParams']
          : ['SensorDownlinkConfigParams']
    }),
    updateSensorDownlinkConfigParams: builder.mutation<
      [any] | any,
      { config_params: Record<string, number> } & ({ mac: string; buildingId?: never } | { mac?: never; buildingId: number })
    >({
      query: (args) => {
        const { config_params, buildingId, mac } = args;
        const url = mac ? `/sensors/${mac}/downlink-config-params` : `/buildings/${buildingId}/downlink-config-params`;
        return { url, method: 'PUT', body: config_params };
      }
    }),
    removeSensorGallonsThreshold: builder.mutation<void, number>({
      query: (id) => ({ url: `/sensors/gallon-thresholds/${id}`, method: 'delete' }),
      invalidatesTags: (_, __, id) => [{ type: 'SensorGallonsThreshold', id }]
    }),
    createSensorGallonsThreshold: builder.mutation<void, Omit<SensorGallonsThreshold, 'id' | 'created' | 'modified'>>({
      query: (body) => ({ url: `/sensors/gallon-thresholds`, method: 'post', body }),
      invalidatesTags: ['SensorGallonsThreshold']
    }),
    getSubmissionsGallonsCountByEntity: builder.query<
      { gallons_count: number },
      { entity_key: 'companies' | 'properties' | 'buildings' | 'units' } & SensorByEntityFilter &
        PaginatedReq & { start_date?: number; end_date?: number; appliance_id?: number }
    >({
      query: ({ entity_key, id, ...arg }) => {
        const { filter, ...others } = arg;
        return { url: `/${entity_key}/${id}/sensors/submissions-gallons-count`, params: { ...filter, ...others } };
      }
    })
  }),
  overrideExisting: false
});

export const {
  useRemoveSensorMutation,
  useUpdateSensorStateMutation,
  useUpdateSensorStatusMutation,
  useAssignSensorMutation,
  useLazyGetSensorByMacQuery,
  useUpdateConfigParamsMutation,
  useGetSensorsConfigDefaultsQuery,
  useUpdateConfigDefaultsMutation,
  useStartTestingMutation,
  useLazyStopTestingQuery,
  useGetSensorQuery,
  useLazyGetTopSensorsByMacQuery,
  useGetUnassignedSensorsQuery,
  useGetSensorsQuery,
  useUpdateSensorNotificationExcludedMutation,
  useUpdateSensorNotificationExcludedByMacMutation,
  useGetDownlinkConfigParamsQuery,
  useUpdateSensorDownlinkConfigParamsMutation,
  useGetSensorsGallonsThresholdQuery,
  useUpdateSensorGallonsThresholdMutation,
  useRemoveSensorGallonsThresholdMutation,
  useCreateSensorGallonsThresholdMutation,
  useGetAssignedSensorsQuery,
  useLazyGetSubmissionsGallonsCountByEntityQuery,
  useGetSensorByMacQuery
} = extendedApi;
