import { ReactNode } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material';

export type DialogAction = 'ok' | 'cancel';

interface ConfirmDialogProps extends DialogProps {
  onAction?: (value: DialogAction) => void;
  text?: ReactNode;
  okBtnLabel?: string;
  cancelBtnLabel?: string;
  hideCancelBtn?: boolean;
}

function ConfirmDialog({ onAction, text, okBtnLabel, cancelBtnLabel, hideCancelBtn, ...other }: ConfirmDialogProps) {
  const handleCancel = () => {
    onAction?.('cancel');
  };
  const handleOk = () => {
    onAction?.('ok');
  };

  return (
    <Dialog sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: 400, maxHeight: 435 } }} {...other}>
      <DialogTitle>{other.title ?? 'Confirmation'}</DialogTitle>
      <DialogContent>{text ?? 'Are you sure?'}</DialogContent>
      <DialogActions>
        {!hideCancelBtn && (
          <Button color="error" autoFocus onClick={handleCancel}>
            {cancelBtnLabel || 'Cancel'}
          </Button>
        )}
        <Button variant="contained" onClick={handleOk} sx={{ mr: 0.5 }}>
          {okBtnLabel || 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
