export type Unit = {
  id: number;
  unit_type_id: number;
  unit_category_id: number;
  building_id: number;
  name: string;
  tenants_adults_count: number;
  tenants_kids_count?: number | null;
  bathrooms_count: number;
  created: string;
  modified: string;
  install_date?: string | null;
  unit_state_id?: number | null;
};

export type UnitGallonsCountRes = {
  unit_id: number;
  total_gallons_count: number;
  gallons_ptpd_count: number;
};

export type UnitGallonsCountReq = {
  unitId: number;
  applianceId: number;
  start_date?: string | number;
  end_date?: string | number;
};

export enum UnitTypesEnum {
  APARTMENT = 1,
  HOTEL_ROOM = 2,
  RETAIL = 3,
  LAUNDRY_ROOM = 4,
  MAIN_LINE = 5,
  IRRIGATION = 6
}

export type UnitCategory = {
  id: number;
  name: string;
};

export type UnitType = {
  id: number;
  name: string;
  unit_category_id: number;
};

export type UnitState = {
  id: number;
  name: string;
};

export const EXTERNAL_CATEGORIES = [2, 3];

export const isUnitExternalType = (categoryId: number) => EXTERNAL_CATEGORIES.includes(categoryId);

export enum UnitCategoriesEnum {
  INTERNAL_UNITS = 1,
  COMMON_AREAS = 2,
  BUILDING = 3
}

export enum UnitStatesEnum {
  OCCUPIED = 1,
  VACANT = 2,
  VACATION = 3,
  REMODELING = 4
}
