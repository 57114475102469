import { useEffect, useState } from 'react';

// redux
import { useLazyGetBuildingQuery } from 'store/reducers/api/building';
import { useLazyGetTimezoneQuery } from 'store/reducers/api/location';
import { useLazyGetPropertyQuery } from 'store/reducers/api/property';
import { useLazyGetUnitQuery } from 'store/reducers/api/unit';

export interface UseGetTimezone {
  propertyId?: number | null;
  buildingId?: number | null;
  unitId?: number;
}

const useGetTimezone = ({ propertyId, buildingId, unitId }: UseGetTimezone) => {
  const [timezone, setTimezone] = useState<string>();

  const [getTimezone, { isFetching: isFetchingTimeZone }] = useLazyGetTimezoneQuery();
  const [getProperty, { isFetching: isFetchingProperty }] = useLazyGetPropertyQuery();
  const [getBuilding, { isFetching: isFetchingBuilding }] = useLazyGetBuildingQuery();
  const [getUnit, { isFetching: isFetchingUnit }] = useLazyGetUnitQuery();

  const isFetching = isFetchingTimeZone || isFetchingProperty || isFetchingBuilding || isFetchingUnit;

  useEffect(() => {
    const getData = async () => {
      let _propertyId = propertyId;
      let _buildingId = buildingId;

      if (unitId) {
        const unit = await getUnit(unitId, true).unwrap();
        _buildingId = unit?.building_id;
      }

      if (_buildingId) {
        const building = await getBuilding(_buildingId, true).unwrap();
        _propertyId = building?.property_id;
      }

      if (_propertyId) {
        const property = await getProperty(_propertyId, true).unwrap();

        if (property?.timezone_id) {
          const timezone = await getTimezone(property.timezone_id, true).unwrap();
          setTimezone(timezone?.timezone);
        }
      }
    };

    getData();
  }, [propertyId, buildingId, unitId, getProperty, getBuilding, getUnit, getTimezone]);

  return { timezone, isFetching } as const;
};

export default useGetTimezone;
