// material-ui
import { styled } from '@mui/material/styles';
import AppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

// project import
import { drawerWidth } from 'config';

// ==============================|| HEADER - APP BAR STYLED ||============================== //

interface Props extends MuiAppBarProps {
  open?: boolean;
  isDrawerActive?: boolean;
}

const AppBarStyled = styled(AppBar, { shouldForwardProp: (prop) => !['open', 'isDrawerActive'].includes(prop as string) })<Props>(
  ({ theme, open, isDrawerActive }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    ...(!open &&
      isDrawerActive && {
        width: `calc(100% - ${theme.spacing(7.5)})`
      }),
    ...(open &&
      isDrawerActive && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
      })
  })
);

export default AppBarStyled;
