import axios from 'axios';
import { stringify } from 'qs';
import { getToken } from 'utils';
import { getBaseUrl } from './helper';

const axiosServices = axios.create({
  baseURL: getBaseUrl()
});

axiosServices.interceptors.request.use(function (config) {
  const token = getToken();

  config.headers = {
    Authorization: `Bearer ${token}`
  };

  config.paramsSerializer = (params) => {
    return stringify(params);
  };

  return config;
});

export default axiosServices;
