import { serverApi } from '../index';

// types
import { PaginatedReq, PaginatedRes } from 'types/pagination';
import { AddUserRole, CreateUserReq, UpdateUserReq, UserProfile, UserRole } from 'types/auth';
import { NotificationContact } from 'types/notification';

const extendedApi = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<PaginatedRes<UserProfile>, (PaginatedReq | void) & { showAlsoNotificationContacts?: boolean }>({
      query: (arg) => {
        const { filter, ...others } = arg || {};
        return { url: '/users', params: { ...others, ...filter } || undefined };
      },
      providesTags: (result) => (result ? [...result.data.map(({ id }) => ({ type: 'User' as const, id })), 'User'] : ['User'])
    }),
    getUser: builder.query<UserProfile, number>({
      query: (id) => {
        return `/users/${id}`;
      },
      providesTags: (_, __, id) => [{ type: 'User', id }]
    }),
    getUserRoles: builder.query<UserRole[], number>({
      query: (id) => `/users/${id}/roles`,
      providesTags: (result) => (result ? [...result.map(({ id }) => ({ type: 'UserRole' as const, id })), 'UserRole'] : ['UserRole'])
    }),
    getUserNotificationContacts: builder.query<NotificationContact[], number>({
      query: (id) => `/users/${id}/notification-contacts`,
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'NotificationContact' as const, id })), 'NotificationContact']
          : ['NotificationContact']
    }),
    createUserNotificationContact: builder.mutation<void, Omit<NotificationContact, 'id'>>({
      query: ({ user_id, ...body }) => ({ url: `/users/${user_id}/notification-contacts`, method: 'post', body }),
      invalidatesTags: ['NotificationContact']
    }),
    createUser: builder.mutation<
      UserProfile,
      CreateUserReq & {
        selected_role_id: number;
      }
    >({
      query: (body) => ({ url: `/users`, method: 'post', body }),
      invalidatesTags: ['User']
    }),
    updateUser: builder.mutation<void, UpdateUserReq>({
      query: (data) => {
        const { id, ...body } = data;
        return { url: `/users/${id}`, method: 'put', body };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'User', id }]
    }),
    removeUser: builder.mutation<void, number>({
      query: (id) => ({ url: `/users/${id}`, method: 'delete' }),
      invalidatesTags: (_, __, id) => [{ type: 'User', id }]
    }),
    removeUserRole: builder.mutation<void, { roleId: number }>({
      query: ({ roleId }) => ({ url: `/users/roles/${roleId}`, method: 'delete' }),
      invalidatesTags: ['UserRole', 'NotificationContact']
    }),
    createUserRole: builder.mutation<void, AddUserRole>({
      query: ({ user_id, ...body }) => ({ url: `/users/${user_id}/role`, method: 'post', body }),
      invalidatesTags: ['UserRole', 'User']
    }),
    getTopUser: builder.query<UserProfile[], string>({
      query: (name) => ({ url: `/users/search-top/${encodeURIComponent(name)}/5` }),
      providesTags: (result) => (result ? [...result.map(({ id }) => ({ type: 'User' as const, id })), 'User'] : ['User'])
    })
  }),
  overrideExisting: false
});

export const {
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useLazyGetUserQuery,
  useRemoveUserMutation,
  useLazyGetUserRolesQuery,
  useGetUserRolesQuery,
  useRemoveUserRoleMutation,
  useCreateUserRoleMutation,
  useLazyGetTopUserQuery,
  useGetUserQuery,
  useGetUserNotificationContactsQuery,
  useCreateUserNotificationContactMutation
} = extendedApi;
