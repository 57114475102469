import { CSSProperties } from 'react';
import { Link, useMatches } from 'react-router-dom';

// material-ui
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { Divider, Grid, Typography } from '@mui/material';

// project imports
import MainCard from '../MainCard';

// types
import { OverrideIcon } from 'types/root';
import { NavItemType } from 'types/menu';
import { RouteHandle } from 'types/route-handle';

// ==============================|| BREADCRUMBS ||============================== //

export interface BreadCrumbSxProps extends CSSProperties {
  mb?: string;
  bgcolor?: string;
}

interface Props {
  card?: boolean;
  divider?: boolean;
  icon?: boolean;
  icons?: boolean;
  maxItems?: number;
  navigation?: { items: NavItemType[] };
  rightAlign?: boolean;
  separator?: OverrideIcon;
  title?: boolean;
  titleBottom?: boolean;
  sx?: BreadCrumbSxProps;
}

const Breadcrumbs = ({
  card,
  divider = true,
  icon,
  icons,
  maxItems,
  navigation,
  rightAlign,
  separator,
  title,
  titleBottom,
  sx,
  ...others
}: Props) => {
  const matches = useMatches();
  const crumbs = matches
    .filter((match) => Boolean(match.handle))
    .map((match) => {
      const { collapse, crumb, subtitle, title } = match.handle as RouteHandle;
      return { crumb: crumb?.(match.data), collapse, title: title?.(match.data), subtitle: subtitle?.(match.data), path: match.pathname };
    });

  // item separator
  const SeparatorIcon = separator!;
  const separatorIcon = separator ? <SeparatorIcon style={{ fontSize: '0.75rem', marginTop: 2 }} /> : '/';

  const lastCrumb = crumbs.length ? crumbs[crumbs.length - 1] : undefined;
  const lastTitle = [...crumbs].reverse().find((x) => x.title)?.title;
  const lastSubtitle = [...crumbs].reverse().find((x) => x.subtitle)?.subtitle;

  return (
    <MainCard
      border={card}
      sx={card === false ? { mb: 3, bgcolor: 'transparent', ...sx } : { mb: 3, ...sx }}
      {...others}
      content={card}
      shadow="none"
    >
      <Grid
        container
        direction={rightAlign ? 'row' : 'column'}
        justifyContent={rightAlign ? 'space-between' : 'flex-start'}
        alignItems={rightAlign ? 'center' : 'flex-start'}
        spacing={1}
      >
        {title && !titleBottom && (
          <Grid item>
            <Typography variant="h2">{lastCrumb?.crumb}</Typography>
          </Grid>
        )}
        <Grid item>
          <MuiBreadcrumbs aria-label="breadcrumb" maxItems={maxItems || crumbs.length} separator={separatorIcon}>
            {crumbs.map((item, index) =>
              crumbs.length - 1 !== index && !item.collapse ? (
                <Typography key={index} component={Link} to={item.path} variant="h6" sx={{ textDecoration: 'none' }} color="textSecondary">
                  {item.crumb}
                </Typography>
              ) : (
                <Typography key={index}>{item.crumb}</Typography>
              )
            )}
          </MuiBreadcrumbs>
        </Grid>
        {title && titleBottom && (
          <Grid item sx={{ mt: card === false ? 0.25 : 1 }}>
            <Typography variant="h2">{lastCrumb?.title || lastTitle || lastCrumb?.crumb}</Typography>
            {(lastCrumb?.subtitle || lastSubtitle) && <Typography variant="h4">{lastCrumb?.subtitle || lastSubtitle}</Typography>}
          </Grid>
        )}
      </Grid>
      {card === false && divider !== false && <Divider sx={{ mt: 2 }} />}
    </MainCard>
  );
};

export default Breadcrumbs;
