import { lazy } from 'react';
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';

// hooks
import useAuth from 'hooks/useAuth';

// components
import ComponentsRoutes from './ComponentsRoutes';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import Loadable from 'components/Loadable';

// utils
import { getDefaultPath } from 'utils/helper';
import { NavigationListener } from './NavigationListener';

const MaintenanceError404 = Loadable(lazy(() => import('pages/maintenance/404')));

// ==============================|| ROUTING RENDER ||============================== //

export default function Router() {
  const { isLoggedIn, activeRole } = useAuth();

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <>
          <NavigationListener />
          <Outlet />
        </>
      ),
      children: [
        {
          path: '/',
          element: <Navigate to={isLoggedIn && activeRole ? getDefaultPath(activeRole?.user_role_type_id) : 'login'} />
        },
        LoginRoutes,
        ComponentsRoutes,
        MainRoutes,
        {
          path: '*',
          element: <MaintenanceError404 />
        }
      ]
    }
  ]);

  return <RouterProvider router={router} />;
}
