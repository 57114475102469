export type Building = {
  id: number;
  building_type_id: number;
  building_installation_type_id?: number | null;
  property_id: number;
  name: string;
  street: string | null;
  postal: string | null;
  image_url?: string | null;
  created: string;
  modified: string;
  self_installed: number;
  contracted_units: number | null;
  survey_status: BuildingSurveyStatusesEnum;
  survey_data: string;
  shipping_date: string | null;
  install_date?: string | null;
};

export const surveyValues = ['No', '1/2', '3/8', '5/8'] as const;
export const surveyYesNoValues = ['No', 'Yes'] as const;
export const surveyTubValues = ['No', 'Galvanized', 'Copper'] as const;
export const surveyMainLineValues = ['No', '3/4', '1', '2'] as const;
export const surveyWaterLineValues = ['No', '3/8', '1/2'] as const;

export type SurveyValue = typeof surveyValues[number] | typeof surveyYesNoValues[number] | string;

export type SurveyItemData = {
  unit_id: number;
} & Record<SurveyAppliancesEnum, SurveyValue>;
export type SurveyData = SurveyItemData[];

export type Survey = Record<SurveyAppliancesEnum, SurveyValue>;

export type BuildingUnitsCount = {
  building_id: number;
  units_count: number;
};

export enum SurveyAppliancesEnum {
  KITCHEN_COLD = 1,
  KITCHEN_HOT = 2,
  MAIN_BATHROOM_COLD = 3,
  MAIN_BATHROOM_HOT = 4,
  MAIN_TOILET = 5,
  MAIN_SHOWER = 6,
  MAIN_TUB = 7,
  SECOND_BATHROOM_COLD = 8,
  SECOND_BATHROOM_HOT = 9,
  SECOND_TOILET = 10,
  WASHING_MACHINE = 11,
  DISHWASHER = 12,
  NOTES = 13,
  SECOND_SHOWER = 14,
  SECOND_TUB = 15,
  THIRD_BATHROOM_COLD = 16,
  THIRD_BATHROOM_HOT = 17,
  THIRD_TOILET = 18,
  MAIN_LINE = 19,
  THIRD_SHOWER = 20,
  THIRD_TUB = 21,
  WATER_LINE = 22
}

export enum BuildingSurveyStatusesEnum {
  NONE = 0,
  IN_PROGRESS = 1,
  APPROVED_CUSTOMER = 2,
  COMPLETED = 3
}

export type CreateBuildingInput = Omit<Building, 'created' | 'modified' | 'id'>;
export type UpdateBuildingInput = Omit<Building, 'created' | 'modified' | 'property_id'>;

export type BuildingInstaller = {
  id: number;
  building_id: number;
  name: string;
  phone?: string | null;
  email?: string | null;
  created: string;
  modified: string;
};
